import { NgModule } from '@angular/core';
import { PaginationComponent } from './pagination.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [FormsModule,CommonModule],
   exports: [PaginationComponent],
   declarations: [PaginationComponent],
   providers: [],
})
export class PaginationModule{ }