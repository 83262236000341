import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from '../../globals';
import { ModelList } from '../../include/modellist';
import { ToastMode } from '../../include/structures';
import { SystemService } from '../../lib/system.service';
import { UploadService } from '../../lib/upload.service';

@Component({
  selector: 'lib-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.css']
})
export class SystemComponent extends ModelList<any> implements OnInit {

  fileToUpload: File = null;

  typeUpdate=1;

  constructor(
    private fb1: FormBuilder,
    private systemService: SystemService,
    private uploadService:UploadService
    
  ) {
    super(systemService,fb1);
   }
   sql="";

  ngOnInit(): void {
  }

  exportDbSchema(){
    this.systemService.exportSchemaDb().subscribe(()=>{
      Globals.message.showToaster("Schema database esportato correttamente!",ToastMode.SUCCESS);
    });
  }

  compareSchemaDb(){
    this.systemService.compareSchemaDb().subscribe((result)=>{
      this.sql=result;
    });
  }

  createArchive(){
    Globals.loading=true;
    this.systemService.createArchive().subscribe((result)=>{
      Globals.loading=false;
      Globals.message.showToaster("Creazione archivio completato!",ToastMode.SUCCESS);
      window.open(result);
    });
  }

  createDbUpdateFile(){
    Globals.loading=true;
    this.systemService.createDbUpdateFile().subscribe((result)=>{
      Globals.loading=false;
      Globals.message.showToaster("Creazione archivio completato!",ToastMode.SUCCESS);
      window.open(result);
    });
  }

  createClientFile(){
    Globals.loading=true;
    this.systemService.createClientFile().subscribe((result)=>{
      Globals.loading=false;
      Globals.message.showToaster("Creazione archivio completato!",ToastMode.SUCCESS);
      window.open(result);
    });
  }

  createServerFile(){
    Globals.loading=true;
    this.systemService.createServerFile().subscribe((result)=>{
      Globals.loading=false;
      Globals.message.showToaster("Creazione archivio completato!",ToastMode.SUCCESS);
      window.open(result);
    });
  }



  handleFileInput(files: FileList) {
    
    this.fileToUpload = files.item(0);

    //verifica se il file è conforme al tipo di aggiornamento
    if(this.typeUpdate==1){
      if(this.fileToUpload.name!="dbschema.json"){
        alert("File non corretto. Necessario file dbschema.json");
        return;
      }

    }

    if(this.typeUpdate==2){
      if(this.fileToUpload.name!="client.zip"){
        alert("File non corretto. Necessario file client.zip");
        return;
      }

    }

    if(this.typeUpdate==3){
      if(this.fileToUpload.name!="server_core.zip"){
        alert("File non corretto. Necessario file server_core.zip");
        return;
      }

    }


    Globals.setLoading(true); 
    this.uploadService.uploadFile(this.fileToUpload,"upgrade",(result)=>{

      this.systemService.upgradeWithArchive(result).subscribe((upgraded)=>{
        if(upgraded)
          Globals.message.showToaster("Aggiornamento completato!",ToastMode.SUCCESS);
        else
          Globals.message.showToaster("Errore durante la fase di aggiornamento!",ToastMode.DANGER);
        
        Globals.setLoading(false);
      });
      
     
     
    },this.typeUpdate==4?true:false);
}


}
