import { Injectable } from '@angular/core';
import { InventoriesDetails } from 'projects/c1-backend/src/app/db/inventories';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';
import { Observable } from 'rxjs';
import { Booking } from '../db/booking';

@Injectable({
  providedIn: 'root'
})
export class BookingService extends WSService<Booking> {

  constructor() { super("booking")}

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<any>{
    return Globals.ws.requestUrl("booking","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }

  getBookingByIDs(ids:string[]):Observable<Booking[]>{
    return Globals.ws.requestUrl<Booking[]>("booking","getItems",["b.id IN ("+ids.join(",")+")"],true,0,9999999999);
  
  }

  getListProducts(id_category:number,id_inventory:number,date_start:string,date_end:string,time_start:string,time_end:string):Observable<Product[]>{
    return Globals.ws.requestUrl<Product[]>("booking","getListProducts",[id_category.toString(),id_inventory.toString(),date_start,date_end,time_start,time_end]);
  }

  getListProductsForTour(id_category:number,id_inventory:number,date_start:string,date_end:string,time_start:string,time_end:string,adults:number,children:number):Observable<any[]>{
    return Globals.ws.requestUrl<Product[]>("booking","getListProductForTour",[id_category.toString(),id_inventory.toString(),date_start,date_end,time_start,time_end,adults.toString(),children.toString()]);
  }

  getProductInventory(id_inventory,ids_category=[]):Observable<InventoriesDetails[]>{
    return Globals.ws.requestUrl<InventoriesDetails[]>("booking","getProductInventory",[id_inventory,ids_category.join(",")]);
    
  }

  getProductInventory2(id_inventory):Observable<InventoriesDetails[]>{
    return Globals.ws.requestUrl<InventoriesDetails[]>("booking","getProductInventory2",[id_inventory]);
    
  }
  
  getBookingByCode(code):Observable<Booking>{
    return Globals.ws.requestUrl<Booking>("booking","getBookingByCode",[code]);
  
  }

  getBookingInventories(id_booking):Observable<InventoriesDetails[]>{
    return Globals.ws.requestUrl<InventoriesDetails[]>("booking","getBookingInventories",[id_booking]);
    
  }

  getTodayBookings(id_shop_start):Observable<Booking[]>{
    return Globals.ws.requestUrl<Booking[]>("booking","getTodayBookings",[id_shop_start]);
  
  }

  getBookings(id_shop_start):Observable<Booking[]>{
    return Globals.ws.requestUrl<Booking[]>("booking","getBookings",[id_shop_start]);
  
  }

  saveAndPay(item:Booking,payment_method_id,userAutoAssign,oncomplete){
     Globals.ws.send("booking","saveAndPay",item,[payment_method_id,userAutoAssign],oncomplete);
  }




  confirmPayment(item:Booking,payment_method_id,oncomplete){
    Globals.ws.send("booking","confirmPayment",item,[payment_method_id],oncomplete);
 }

  getPricelistRental(id_shop):Observable<any[]>{
    return Globals.ws.requestUrl<any[]>("booking","getPricelistRental",[id_shop]);
  }

  printInvoice(id_booking):Observable<string>{
    return Globals.ws.requestUrl<string>("booking","printInvoice",[id_booking]);
    
  }

  printContract(id_booking):Observable<string>{
    return Globals.ws.requestUrl<string>("booking","printContract",[id_booking]);
    
  }


  emailConfirmCheckin(id_booking):Observable<string>{
    return Globals.ws.requestUrl<string>("booking","emailConfirmCheckin",[id_booking]);
    
  }

  emailConfirmCheckout(id_booking):Observable<string>{
    return Globals.ws.requestUrl<string>("booking","emailConfirmCheckout",[id_booking]);
    
  }

  emailMonitoringAlarm(id_booking,sn):Observable<string>{
    return Globals.ws.requestUrl<string>("booking","emailMonitoringAlarm",[id_booking,sn]);
    
  }

  getCalendar(start,end,id_shop_start):Observable<any[]>{
    return Globals.ws.requestUrl<any[]>("booking","getCalendar",[start,end,id_shop_start]);
  }


  StripeConfirmPayment(id_booking,payment_intent_id):Observable<any>{
    return Globals.ws.requestUrl<any[]>("booking","StripeConfirmPayment",[id_booking,payment_intent_id]);
  }

  getRentalProducts(only_in_stock,id_inventory=0,exclude_categories=""):Observable<any>{
    return Globals.ws.requestUrl<any[]>("booking","getRentalProducts",[only_in_stock,id_inventory.toString(),exclude_categories]);

  }
}
