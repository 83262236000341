import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboboxComponent } from './combobox.component';
import { FormsModule } from '@angular/forms';
import { ComboBoxPipe } from './combobox';



@NgModule({
  declarations: [ComboboxComponent,ComboBoxPipe],
  exports:[ComboboxComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers:[]
})
export class ComboboxModule { }
