import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';



@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.sass']
})

export class DocumentsComponent implements OnInit {

  @Input()
  id_address:number;

  @Input()
  mode:string;

  @Input()
  modalWindow:any={};

  @Input()
  recordSelected:any={};

  tab=1;

  get user(){
    return Globals.user;
  }

  constructor( 
    
  ) {
   }

  ngOnInit(): void {

    
    
  }




}


