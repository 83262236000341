import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { ActionsComponent } from './actions/actions.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { CustomfieldssettingsComponent } from './customfields-settings/customfields-settings.component';
import { CustomfunctionsComponent } from './customfunctions/customfunctions.component';
import { ModulesComponent } from './modules/modules.component';
import { NotificationComponent } from './notification/notification.component';
import { SystemComponent } from './system/system.component';
import { TemplateEmailComponent } from './template-email/template-email.component';
import { TemplatesComponent } from './templates/templates.component';
import { TypesSettingsComponent } from './types/types.component';
import { UsersComponent } from './users/users.component';


const routes: Routes = [
  { path: 'actions', component: ActionsComponent,canActivate:[AuthGuard] },
  { path: 'alarms', component: AlarmsComponent ,canActivate:[AuthGuard]},
  { path: 'customfields', component: CustomfieldssettingsComponent ,canActivate:[AuthGuard]},
  { path: 'modules', component: ModulesComponent ,canActivate:[AuthGuard]},
  { path: 'notifications', component: NotificationComponent ,canActivate:[AuthGuard]},
  { path: 'templates', component: TemplatesComponent ,canActivate:[AuthGuard]},
  { path: 'users', component: UsersComponent ,canActivate:[AuthGuard]},
  { path: 'template_email', component: TemplateEmailComponent ,canActivate:[AuthGuard]},
  { path: 'system', component: SystemComponent ,canActivate:[AuthGuard]},
  { path: 'customfunctions', component: CustomfunctionsComponent ,canActivate:[AuthGuard]},
  { path: 'types', component: TypesSettingsComponent ,canActivate:[AuthGuard]}



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
