import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'lib-selectconfigfile',
  templateUrl: './selectconfigfile.component.html',
  styleUrls: ['./selectconfigfile.component.css']
})
export class SelectconfigfileComponent implements OnInit,OnChanges {
  @Input()
  configs=[]

  selected="";

  timer=3;

  timerInterval=null;

  constructor() { }

  ngOnInit(): void {

    let configMem=null;
    try{
      configMem=JSON.parse(localStorage.getItem("c1_autoselect_config"));
    }catch{
      configMem=null;
    }
    if(configMem){
      this.selected=configMem
    }else{
      this.selected=this.configs[0];
    }


    for(let c of this.configs){
      if(this.isArray(c['serverUrl'])){

        if(this.selected['selectedServerUrl']){
          c['selectedServerUrl']=this.selected['selectedServerUrl'];
        }else{
          c['selectedServerUrl']=c['serverUrl'][0];
        }


        
      }else{
        c['selectedServerUrl']=c['serverUrl'];
      }
     
    }
    
    

    
    this.timerInterval=setInterval(()=>{
      if(this.timer>0){
        this.timer=this.timer-1;
      }else{
        this.stopTimer();
        this.select()
      }
    },1000)

  }

  stopTimer(){
    this.timer=0;
    clearInterval(this.timerInterval);
  }

  ngOnChanges(){
   
  }

  select(config=null){
    if(config!=null)
     this.selected=config;
    if(this.selected['selectedServerUrl'])
      this.selected['serverUrl']=this.selected['selectedServerUrl']
    this['modalWindow'].close("success");

    //memorizza in cache le impostazioni
    localStorage.setItem("c1_autoselect_config",JSON.stringify(this.selected));



  }

  isArray(value){
    return Array.isArray(value);
  }

}
