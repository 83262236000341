import { Component, OnInit, Input,ViewChild,TemplateRef } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { InventoriesDetailsService } from '../../services/inventoriesdetails.service';
import {ProductsService} from '../../services/products.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../db/product';
import { Inventories,InventoriesDetails, InventoriesTotal,InventoryMode,SNAvaible } from '../../db/inventories';
import {LogstockComponent} from './logstock/logstock.component';
import {LoginventoryComponent} from './loginventory/loginventory.component';


@Component({
  selector: 'app-inventories',
  templateUrl: './inventories.component.html',
  styleUrls: ['./inventories.component.sass']
})

export class InventoriesComponent  implements OnInit {

  @ViewChild(LogstockComponent) logstockComponent;
  @ViewChild(LoginventoryComponent) loginventoryComponent;

  @Input()
  mode:string;

  @Input()
  id_product:number=0;

  @Input()
  sn:string="";

  tab=0;
  inventories:Inventories[];

    /* FAST LOAD/ULOAD */

    fastLUquantity:number=1;
    fastLUinventory:number=Globals.user.getParam("id_inventory",0);
    @Input()
    afterSave:Function;

    constructor( 
      private InventoriesDetailsService: InventoriesDetailsService,
      private productsService: ProductsService,
      
    ) {
      
     }

  ngOnInit(){
    this.InventoriesDetailsService.getInventories().subscribe((items)=>{
      this.inventories=items;
    })
  }

   //Effettua il carico rapido all'interno di una scheda prodotto
   load(type=1){

    //prendi il record del prodotto
    this.productsService.getItem(this.id_product).subscribe((item)=>{

      let product:Product=new Product();
      Object.assign(product,item);

      let recordToLoad=new InventoriesDetails();
      recordToLoad.description=product.brand+" "+product.name;
      recordToLoad.id_product=this.id_product;
      recordToLoad.type=type;
      recordToLoad.quantity=this.fastLUquantity;
      recordToLoad.netprice=product.getPrice(recordToLoad.quantity,recordToLoad.type);
      if(recordToLoad.type==InventoryMode.load){
        //recordToLoad.discount=product.getEquivalentDiscountCost();
        recordToLoad.discount1=product.discountcost1;
        recordToLoad.discount2=product.discountcost2;
        recordToLoad.discount3=product.discountcost3;
        
      }
        
      recordToLoad.tax=item.tax;
      recordToLoad.id_inventory=this.fastLUinventory;

      this.InventoriesDetailsService.save(recordToLoad,()=>{
        //this.getItems();
        this.loginventoryComponent.getItems();
        this.logstockComponent.getItems();
        if(this.afterSave)
          this.afterSave();
      });
     
    });

    

  }

}


