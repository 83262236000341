import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { Inventories, InventoriesDetails } from '../../../db/inventories';
import { InventoriesService } from '../../../services/inventories.service';
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { TypesService } from '../../../services/types.service';
import { ProductsDetailsComponent } from '../../products/products-details/products-details.component';
import { LoginventoryComponent } from '../loginventory/loginventory.component';

@Component({
  selector: 'app-inventories-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent extends ModelList<Inventories> implements OnInit {
  id_type=0;
  id_category=0;
  filter_difference=0;
  types=[];
  categories=[];
  total_difference_grossvalue=0;

  constructor(
    private fb1: FormBuilder,
    private InventoriesService: InventoriesService,
    private InventoriesDetailsService: InventoriesDetailsService,
    private productcategoriesService:ProductcategoriesService,
    private typesService:TypesService

  ) {
    super(InventoriesService,fb1);
   }

  ngOnInit(): void {

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    });

    this.typesService.getTypes("products").subscribe((items)=>{
      this.types=items;
    });

    this.getAnalytics();
  }

  getAnalytics(){
    this.InventoriesService.getAnalytics(this.id_category,this.id_type,this.filter_difference).subscribe((items)=>{
      this.list=items;
      this.InventoriesService.getTotalAnalytics(this.id_category,this.id_type,this.filter_difference).subscribe((total)=>{
        console.log(total);  
        this.total_difference_grossvalue=total.total_difference_grossvalue;
      });
      
    })
  }

  calculateRealStock(r){
    let load=parseInt(r.total_load)+parseInt(r.total_load_document);
    let unload=parseInt(r.total_unload)+parseInt(r.total_unload_document);

    return load-unload;
    
  }

  calculateTeoricalStock(r){
    let load=parseInt(r.total_load_document);
    let unload=parseInt(r.total_unload_document);

    return load-unload;
  }

  differenceTeoricalReal(r){
    return this.calculateRealStock(r)-this.calculateTeoricalStock(r);
  }

  openProduct(id_product){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":id_product},
      
    ],()=>{

    });
  }

  openHistory(id_product){
    Globals.modal.showModal(LoginventoryComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_product","value":id_product},
    ],()=>{
      
    })

  }

}
