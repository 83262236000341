import { Component, OnInit,Input } from '@angular/core';
import { DeadlinesService } from '../../../services/deadlines.service';

@Component({
  selector: 'app-confirmdeadlines',
  templateUrl: './confirmdeadlines.component.html',
  styleUrls: ['./confirmdeadlines.component.css']
})
export class ConfirmdeadlinesComponent implements OnInit {

  @Input()
  list:any[]=[];
  constructor(
    private deadlinesService:DeadlinesService
  ) { }

  ngOnInit(): void {


    this.checkNextDate();
  }


  checkNextDate(){
    if(this.list){
      for(let d of this.list){
        //verifica che la data non sia stata già inserita
        this.deadlinesService.nextDeadline(d).subscribe((items)=>{
          if(items.length>0){
            d['nextDate']=items[0];
            d.id=d['nextDate']['id'];
          }

          
          
          /*Globals.modal.showModal(ConfirmdeadlineComponent,[{"name":"record","value":d}],(instance)=>{
            if(instance!=null)
              deadlinesService.save(d,(id)=>{

              });
           });*/
            
          
        })
      }
    }
  }


}
