import { Injectable } from '@angular/core';

import { WSService } from '../include/service';

@Injectable({
  providedIn: 'root'
})
export class FilesService extends WSService<any> {

  constructor() { super("files") };

  

}
