import { Injectable } from '@angular/core';
import {Contracts, ContractsInstallment} from '../db/contracts';
import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Filter, FilterAdvanced, Ordering } from 'projects/core/src/include/structures';

@Injectable({
  providedIn: 'root'
})

export class ContractsService extends WSService<Contracts> {

  constructor() {super("contracts") }

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<any>{
    return Globals.ws.requestUrl("contracts","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }

  generateInstallments(start,end,amount,tax,amount_type,type,typedetail,id_contract,id_address,params=null):Observable<ContractsInstallment[]>{
    return Globals.ws.requestUrl("contracts","generateInstallments",[start,end,amount,tax,amount_type,type,typedetail,id_contract,id_address,params]);
  }

  setArchivedInstallments(id):Observable<any>{
    return Globals.ws.requestUrl("contracts","setArchivedInstallments",[id]);
  
  }

}

