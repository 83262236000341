import { Component,ViewChild, OnInit ,AfterViewInit, Input, NgZone} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { Type } from '../../../db/type';
import { TypesService } from '../../../services/types.service';
import { AddressesService } from '../../../services/addresses.service';
import { ShopsService } from '../../../services/shops.service';
import { Shops } from '../../../db/shops';
import { AddressComponent } from '../../address/address.component';
import { Globals } from 'projects/core/src/globals';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { SearchgpsComponent } from '../../../modules/searchgps/searchgps.component';
import { InventoriesService } from '../../../services/inventories.service';
import { Inventories } from '../../../db/inventories';
import { CustomfieldsComponent } from 'projects/core/src/common/customfields/customfields.component';



@Component({
  selector: 'app-shops-details',
  templateUrl: './shops-details.component.html',
  styleUrls: ['./shops-details.component.sass']
})

export class ShopsDetailsComponent extends Model<Shops> implements OnInit,AfterViewInit {

  latitude_default:number=38.1037;
  longitude_default:number=15.6369;
  
  @Input()
  id_address:number=0;

  type_shops:Type[];
  detailForm;

  inventories:Inventories[]=[];

  field_required=['name','id_address'];
  
  @ViewChild(CustomfieldsComponent) customfieldsView:CustomfieldsComponent;
  

  constructor(
    private route1: ActivatedRoute,
    private shopsService: ShopsService,
    private typesService: TypesService,
    public addressesService: AddressesService,
    private inventoriesService:InventoriesService
    ) {
      super(route1,shopsService);
    
  }


  onSetMarker():void{
    //let c=L.latLng(this.record.latitude,this.record.longitude);
    //this.map.setView(c,18);
    //this.pin.setLatLng(c);
  }


  ngOnInit(): void {

    //this.location.marker.draggable = true;

    this.title = 'Sede Dettaglio';
    if(this.mode!="modal")
      this.route1.params.subscribe((params) => {
        this.id = params.id
        if(params.id_address)
          this.id_address=params.id_address;
      });

    this.typesService.getTypes("shops").subscribe((items)=>{
      this.type_shops=items;
      this.inventoriesService.getInventories().subscribe((items_i)=>{
        this.inventories=items_i;
        this.getItem();
      });
      
   });


   this.afterGetItem=(()=>{
    if(this.record.id==0 || this.record.id==undefined){
     //this.record.latitude=AppComponent.app.latitude_default;
     //this.record.longitude=AppComponent.app.longitude_default;
     this.record.customfields=[];
     this.record.id_type=this.type_shops.length>0?this.type_shops[0].id:0;
     this.record.status=1;
      //cerca l'anagrafica corretta
     if(this.id_address>0)
      this.addressesService.getItem(this.id_address).subscribe((item)=>{
        this.record.addressItem=item;
        this.record.id_address=item.id;
        this.record.name=item.name;
      });

    }
    
      //visualizza la mappa
      //this.initMap();
  })



    
    //definisci un evento di aggiornamento quando viene salvato un record
    /*this.afterSave=(()=>{
      this.customfieldsView.updateList();
    });*/

    
    

  }

  onCitySelected(event):void{
    console.log(event);
  }

  

 verifyCoordinate() {

  if (this.record.latitude != null) {
    var arr = this.record.latitude.toString().split(",");
    this.record.latitude = parseFloat(arr[0]);
    if (arr.length > 1) this.record.longitude = parseFloat(arr[1]);
  }
  
 }

 addAddress(){
  Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
    if(instance!=null){
      this.record.addressItem=instance['record'];
      this.record.id_address=this.record.addressItem.id;
    }
  })
}

selectAddress(){
  Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona un'anagrafica"}],(instance)=>{
    let r=instance['recordSelected'];

    this.OnSelectAddress(r);


    
    
  });
}

OnViewAddress(r){
  Globals.modal.showModal(AddressDetailsComponent,[
    {"name":"mode","value":"modal"},
    {"name":"id","value":r.id}
  ],(instance)=>{
    if(instance!=null)
      this.OnSelectAddress(instance['record']);
  });
}

OnSelectAddress(r){
  if(r){
    this.record.addressItem=r;
    this.record.addressItem?this.record.id_address=this.record.addressItem.id:this.record.id_address=0;
  }
}

openSearchGPS(){
  Globals.modal.showModal(SearchgpsComponent,[{"name":"search","value":this.record.address+","+this.record.city},{"name":"latitude","value":this.record.latitude},{"name":"longitude","value":this.record.longitude}],(instance)=>{
    if(instance!=null){
      this.record.latitude=instance['latitude'];
      this.record.longitude=instance['longitude'];
      this.record.address=instance['address'];
      this.record.city=instance['city'];
      this.record.zip=instance['zip'];
    }
  });
}

/*
markerDragEnd(m: any, $event: any) {
  this.location.marker.lat = m.coords.lat;
  this.location.marker.lng = m.coords.lng;
  this.findAddressByCoordinates();
 }

 findAddressByCoordinates() {
  this.geocoder.geocode({
    'location': {
      lat: this.location.marker.lat,
      lng: this.location.marker.lng
    }
  }, (results, status) => {
    this.decomposeAddressComponents(results);
  })
}

decomposeAddressComponents(addressArray) {
  if (addressArray.length == 0) return false;
  let address = addressArray[0].address_components;

  for(let element of address) {
    if (element.length == 0 && !element['types']) continue

    if (element['types'].indexOf('street_number') > -1) {
      this.location.address_level_1 = element['long_name'];
      continue;
    }
    if (element['types'].indexOf('route') > -1) {
      this.location.address_level_1 += ', ' + element['long_name'];
      continue;
    }
    if (element['types'].indexOf('locality') > -1) {
      this.location.address_level_2 = element['long_name'];
      continue;
    }
    if (element['types'].indexOf('administrative_area_level_1') > -1) {
      this.location.address_state = element['long_name'];
      continue;
    }
    if (element['types'].indexOf('country') > -1) {
      this.location.address_country = element['long_name'];
      continue;
    }
    if (element['types'].indexOf('postal_code') > -1) {
      this.location.address_zip = element['long_name'];
      continue;
    }
  }
}
*/
}
