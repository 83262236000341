import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {


  @Input()
  title:string="";

  @Input()
  textConfirmBtn="Ok";

  @Input()
  textCancelBtn="Annulla";

  @Input()
  isConfirmDialog:boolean=false;

  @Output()
  OnConfirm:EventEmitter<any>=new EventEmitter();

  @Output()
  OnCancel:EventEmitter<any>=new EventEmitter();
  

  constructor() { }

  ngOnInit(): void {
  }

  confirm(){
    this.OnConfirm.emit();
  }

  cancel(){
    this.OnCancel.emit();
  }

  close(){
    if(this.isConfirmDialog)
      this.OnConfirm.emit();
    else
      this.OnCancel.emit();
  }

}
