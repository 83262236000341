import { Globals } from "projects/core/src/globals";

export class Image{
    file:string;

    get imagePath(){
        try{
            if(this.file=="")
                return Globals.rootpath+"assets/default-placeholder.png";
        return Globals.config.serverUrl+"/"+this.file;
        }catch{
            return Globals.rootpath+"assets/default-placeholder.png";

        }
    }
}