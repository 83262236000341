import {Component, Input, OnInit, Output,EventEmitter} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {FormsModule} from '@angular/forms'
import { ComboBoxPipe } from './combobox';

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  TAB_KEY = 9
}

@Component({
  selector: 'combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.sass'],
  providers: [ComboBoxPipe]
})

export class ComboboxComponent implements OnInit {
  @Input()
  class="form_control";

  @Input()
  dataList: [];
  
  @Input()
  value:any;

  @Input()
  columnName:string;

  @Input()
  columnValue:string;

  
  dummyDataList:any[]=[];
  showDropDown: boolean;
  counter:number;
  
  @Output() onEntrySelected = new EventEmitter();

  @Output() onkeyup:EventEmitter<number>=new EventEmitter();
  onFocusEventAction():void {
    this.counter = -1;
  }
  onBlurEventAction(): void{
    this.showDropDown = false;
  }
  onKeyDownAction(event: KeyboardEvent):void {

    this.showDropDown = true;

    if (event.keyCode === KEY_CODE.UP_ARROW) {
      this.counter = (this.counter === 0)?this.counter:--this.counter;
      this.checkHighlight(this.counter);
      this.value = this.dataList[this.counter][this.columnName];
    }

    if (event.keyCode === KEY_CODE.DOWN_ARROW) {
      this.counter = (this.counter === this.dataList.length-1)?this.counter:++this.counter;
      this.checkHighlight(this.counter);
      this.value = this.dataList[this.counter][this.columnName];
    }
  }
  
  checkHighlight(currentItem):boolean{
    if(this.counter === currentItem) return true;
    else return false;
  }



constructor(private ComboBoxPipe: ComboBoxPipe) {
  this.reset();
}



ngOnInit(){
  this.reset();
}


toogleDropDown():void {
  this.dummyDataList = this.dataList;
  this.showDropDown = !this.showDropDown;
}


reset(): void{
  this.showDropDown = false;
  this.dummyDataList = this.dataList;
} 
   
textChange(value){
  this.dummyDataList = [];
  if(value.length >0){
    this.dummyDataList = this.ComboBoxPipe.transform(this.dataList,this.columnName, value);
    if(this.dummyDataList){
      this.showDropDown = true;
    }
  } else {
    this.reset();
  }
}

   updateTextBox(valueSelected){
     this.value = valueSelected[this.columnValue];
     this.showDropDown = false;
     this.update();
   }


   update(){
    this.onEntrySelected.emit(this.value);
    this.onkeyup.emit(this.value);
   }

   
   
}