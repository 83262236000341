import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { Model } from 'projects/core/src/include/model';
import { Globals } from 'projects/core/src/globals';
import { UploadService } from 'projects/core/src/lib/upload.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { RentalPrice } from 'projects/c1-backend/src/app/db/rentalprice';
import { RentalpriceService } from 'projects/c1-backend/src/app/services/rentalprice.service';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { Shops } from 'projects/c1-backend/src/app/db/shops';
import { ProductsService } from 'projects/c1-backend/src/app/services/products.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { ProductsComponent } from '../../../products/products.component';

@Component({
  selector: 'app-rentalprices-detail',
  templateUrl: './rentalprices-detail.component.html',
  styleUrls: ['./rentalprices-detail.component.css']
})
export class RentalpricesDetailComponent extends Model<RentalPrice> implements OnInit {

  @ViewChild("modalProduct")
  modalProduct:TemplateRef<any>;
  modalProductRef;
  listProducts=[];
  tagSelected="";

  public Editor = ClassicEditor;
  editorOn=true;
  fileToUpload: File = null;
  shops:Shops[]=[];

  get user(){
    return Globals.user;
  }

  constructor(
    private route1: ActivatedRoute,
    private uploadService:UploadService,
    private rentalpriceService:RentalpriceService,
    private shopsService:ShopsService,
    private productsService:ProductsService,
    private modalService:NgbModal
  ) {super(route1,rentalpriceService) }

  ngOnInit(): void {
    this.record=new RentalPrice();
    this.title="Listino prezzi";
    super.ngOnInit.apply(this, arguments); 

   
    this.record.typeprice=2; // precompila il tipo di tariffa 
    this.record.type=2; // precompila il tipo condizione di verifica
    this.record.min_partecipants=1; // precompila il num min di partecipanti
    this.record.min_adults=0; // precompila il num min di adulti
    this.record.min_children=0; // precompila il num min di bambini


    let id_default_address=Globals.parameters.get("general").getParam("default_address",0);
    this.shopsService.getItemsByAddress(id_default_address).subscribe((items)=>{
      this.shops=items;
    });


    this.afterGetItem=()=>{
      if(this.record.shops){
        for(let s of this.record.shops){
          if(s.prices!=""){
            s.pricesObj=JSON.parse(s.prices);
          }
        }
      }

    }
    
    this.beforeSave=()=>{
      for(let s of this.record.shops){
          s.prices=JSON.stringify(s.pricesObj);
      }

      return true;
        
    
    }

    this.getItem();


  }

  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"rentalprices",(result)=>{

      let i:Image=new Image();
      i.file=result;

      //this.record.image.push(i);
      Globals.setLoading(false);
     
    },true);
  }

  calculateRow(r){
    r.gross=(r.value*(1+r.tax/100)).toFixed(2);
  }

  calculateRowInv(r){
    r.value=(r.gross/(1+r.tax/100)).toFixed(4);
  }
  /*
  save(reloadItem:boolean=true,oncomplete:Function=null):void{
    for(let s of this.record.shops)
        s.prices=JSON.stringify(s.prices);
    
    super.save.apply(reloadItem,oncomplete);
  }*/

  addPrice(s){
    let p={};
    p['tag']="";
    p['value']="0";
    p['tax']="0";
    p['gross']="0";
    s.pricesObj.push(p);
  }

  deletePrice(s,p){
    for(let i=0;i<s.pricesObj.length;i++){
      if(s.pricesObj[i]==p){
        s.pricesObj.splice(i,1);
        return;
      }
    }
    
  }

  setToAll(s,p){
    for (let shop of this.record.shops){
      if(shop!=s){
        //verifica che la riga non esista
        let v=false;
        for(let r of shop.pricesObj){
          if(r['tag']==p['tag']){
            r['value']=p['value'];
            r['tax']=p['tax'];
            r['gross']=p['gross'];
            v=true;
          }
        }
        if(!v)
          shop.pricesObj.push(p);
      }
    }
  }

  duplicateShop(s){
    let shop:any={};
    Object.assign(shop,s);
    shop.id=0;
    this.record.shops.push(shop);
  }

  deleteShop(s){
    if(confirm("Sicuro di voler eliminare il punto vendita?")){
      for(let i=0;i<this.record.shops.length;i++){
        if(s==this.record.shops[i]){
          this.record.shops.splice(i,1);
          return;
        }
      }
    }
  }


  openProductList(tag){
    
    this.getProducts(tag,()=>{
      this.modalProductRef=this.modalService.open(this.modalProduct);
   
    })
    
    
  }


  getProducts(tag,complete){
    this.productsService.getProductsByTag(tag).subscribe((items)=>{
      if(items){
        this.listProducts=[];
        for(let item of items){
          let p:Product=new Product();
          Object.assign(p,item);
          this.listProducts.push(p);
        }
      
        this.tagSelected=tag;
        complete();
        
      }
      
    });
  }


  closeProductList(){
    this.modalProductRef.close("success");
  }


  linkProduct(){
    Globals.modal.showModal(ProductsComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance){
        let product:Product=instance['recordSelected'];
        //verifica che il prodotto non abbia gia la tag inserita
        if(product.tags.indexOf(this.tagSelected)==-1){
          if(product.tags==""){
            product.tags=this.tagSelected;
          }else{
            product.tags=product.tags+";"+this.tagSelected;
          }
        }

        this.productsService.save(product,(id)=>{
          this.getProducts(this.tagSelected,()=>{

          });

        },false,false);

      }
    })
  }


  unlinkProduct(product:Product){
    if(confirm("Sicuro di voler dissassociare questo prodotto dalla tag selezionata?")){

    
      //dividi le tag in array
      let tags=product.tags.split(";");
      //trova la tags dall'array ed eliminala
      for(let i=0;i<tags.length;i++){
        if(tags[i]==this.tagSelected){
          tags.splice(i,1);
          continue;
        }
      }
      
      product.tags=tags.join(";");

      //salva il prodotto
      this.productsService.save(product,()=>{
        this.getProducts(this.tagSelected,()=>{

        });
      },false,false)
    }
    
  }

  addShop(){
    let s={};
    s['ordering']=0;
    s['pricesObj']=[];
    this.record.shops.push(s);
  }



}
