import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { Calendar, Tour } from '../db/tour';

@Injectable({
  providedIn: 'root'
})
export class ToursService extends WSService<Tour> {

  constructor() {super("tours") }


  getTours(date:string,adults:number,children:number,id_shop_start:number=0):Observable<Tour[]>{
    return Globals.ws.requestUrl("tours","getTours",[date,adults.toString(),children.toString(),id_shop_start.toString()]);
  }

  getTour(code:string):Observable<Tour>{
    return Globals.ws.requestUrl("tours","getTour",[code]);
  }

  generateDates(id_tour,date_start,date_end,time_start,time_end,id_shop_start,price_adult,price_children,quantity,min_quantity,location_start,days_week,prices,ids=""):Observable<boolean>{
    return Globals.ws.requestUrl("tours","generateDates",[id_tour,date_start,date_end,time_start,time_end,id_shop_start,price_adult,price_children,quantity,min_quantity,location_start,days_week,JSON.stringify(prices),ids]);
  }

  getDate(id_tour,from,to,time_start):Observable<Calendar[]>{
    return Globals.ws.requestUrl("tours","getDate",[id_tour,"",from,to,time_start]);
  }

  getDateGrouped(id_tour):Observable<Calendar[]>{
    return Globals.ws.requestUrl("tours","getDateGrouped",[id_tour]);
  }


  clearAllDates(id_tour):Observable<boolean>{
    return Globals.ws.requestUrl("tours","clearAllDates",[id_tour]);
  }

  deleteDate(id_tour,date_stat,date_to,time_star,time_to,id_shop_start):Observable<boolean>{
    return Globals.ws.requestUrl("tours","deleteDate",[id_tour,date_stat,date_to,time_star,time_to,id_shop_start]);
  }

}
