import { Injectable } from '@angular/core';
import {WSService} from '../include/service'
import { Observable } from 'rxjs';
import { LogUser } from '../include/structures';
import { Globals } from '../globals';
@Injectable({
  providedIn: 'root'
})

export class LogUsersService extends WSService<LogUser> {

  constructor() {super("logusers") }


  getDetailsBySession(session:string):Observable<LogUser[]>{
    return Globals.ws.requestUrl("logusers","getDetailsBySession",[session]);
  }

  getDetailsByIDTable(id_table:number,table:string):Observable<LogUser[]>{
    return Globals.ws.requestUrl("logusers","getDetailsByIDTable",[id_table.toString(),table]);
  }


  
}
