<app-modal title="Nuova data di agenda">

  <div class="m-4" *ngIf="record['nextDate']!=undefined">   
    <i class="fa-2x fas fa-info text-info mr-3"></i>
    <i>Questa voce di agenda è stata già pianificata per il {{record['nextDate'].date | date:'d/M/y'}}!</i>
    <label class="mt-1">Scegli un'azione:</label>
    <div class="btn-group row col-12" role="group">
      <button type="button" class="btn btn-primary" [class.active]="record.id==0" (click)="record.id=0">Crea nuova voce</button>
      <button type="button" class="btn btn-primary" [class.active]="record.id!=0" (click)="record.id=record['nextDate'].id">Sostituisci la voce</button>
    </div>
  </div>
  

  <div class="row">
    <div class="col-sm-6">
        <div class="form-group">
            <label>Data</label>
            <div class="input-group" >
                <input class="form-control" type="date"  name="date"  [(ngModel)]="record.date"  />
            </div>
        </div>
    </div>
    <div class="col-sm-6">
      <div class="form-group">
          <label>Tipologia</label>
          <select class="custom-select" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
            <option *ngFor="let t of type_deadline" [ngValue]="t.id">{{t.name}}</option>
        </select>
      </div>
    </div>

    <div class="col-sm-12">
        <div class="form-group">
          <label>Descrizione</label>
          <div class="input-group" >
              <input class="form-control" type="text"  name="description"  [(ngModel)]="record.description"  />
          </div>
        </div>
    </div>

    <div class="col-sm-12">
          <!-- Extra fields -->
          <app-customfields [list]="record.customfields" [table]="'deadlines'" [id_table]="record.id" [condition_id]="record.id_type" [condition_field]="'id_type'"></app-customfields>
    </div>

    <div class="col-sm-12">
      <div class="form-group">
        <label>Note</label>
        <div class="input-group" >
            <input class="form-control" type="text"  name="note"  [(ngModel)]="record.note"  />
        </div>
      </div>
  </div>
  </div>
</app-modal>