import { NgModule } from '@angular/core';
import { SearchproductComponent } from './searchproduct.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [SearchproductComponent],
   declarations: [SearchproductComponent],
   providers: [],
})
export class SearchproductModule{ }