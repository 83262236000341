<div class="col-12">
  <h1><i class="fas fa-tools"></i> <b>C1</b> Tools</h1>

  <div class="card">
    <div class="card-header">
        <h3>Archivio</h3>
    </div>
    <div class="card-body row">
        <div class="col-md-6">
            <button class="btn btn-info btn-block btn-lg" (click)="createArchive()">Crea archivio completo</button><br/>
            <button class="btn btn-warning btn-block btn-lg" (click)="createDbUpdateFile()">Crea aggiornamento database</button><br/>
        </div>
        <div class="col-md-6">
            <button class="btn btn-danger btn-block btn-lg" (click)="createClientFile()">Crea aggiornamento C1 Client</button><br/>
            <button class="btn btn-secondary btn-block btn-lg" (click)="createServerFile()">Crea aggiornamento C1 Server</button>
        </div>
    
    </div>
  </div>

  <div class="card">
    <div class="card-header">
        <h3>Aggiornamento</h3>
    </div>
    <div class="card-body">
        <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="1">
              <label class="form-check-label">Aggiorna solo database <small>[dbschema.json]</small></label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="3">
                <label class="form-check-label">Aggiorna solo C1 Client <small>[client.zip]</small></label>
              </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="3">
              <label class="form-check-label">Aggiorna solo C1 Server <small>[server_core.zip]</small></label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="4">
              <label class="form-check-label">Aggiorna Tutto</label>
            </div>
        </div>
        
      
      <div class="custom-file">
        <input type="file" class="custom-file-input" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
        <label class="custom-file-label" for="exampleInputFile">Sfoglia</label>
      </div>




<!--       
      <div class="row">
          <div class="col-12">
              <input class="mr-2" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="2">
              <label>Aggiorna solo C1 Client (client.zip)</label>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <input class="mr-2" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="3">
              <label>Aggiorna solo C1 Server (server_core.zip)</label>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <input class="mr-2" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="1">
              <label>Aggiorna solo database (dbschema.json)</label>
          </div>
      <div class="row">
          <div class="col-12">
              <input class="mr-2" type="radio" name="typeUpdate" [(ngModel)]="typeUpdate" value="4">
              <label>Aggiorna Tutto</label>
          </div>
      </div>
      <input type="file"  #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
         -->


    </div>
  </div>
</div>