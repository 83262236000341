export class DocumentTemplate{
    id:number;
    name:string;
    conditions:any;
    details:any;
    referenceprefix:string;
    description:string;
    description2:string;
    typedocument:number;
    id_type:number;
    id_documentsrule:number;
    table:string;
    documentsrule:any;
    enable_cashflow:boolean;
    mode_details:number;
}