import { Component, OnInit,AfterViewInit,Input, Output,ViewChild } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { ProductCategories } from '../../../db/productcategories';
import { ITreeOptions, KEYS, TreeComponent, TREE_ACTIONS } from '@circlon/angular-tree-component';
import { TreecategoriesComponent } from '../../../modules/treecategories/treecategories.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent extends ModelList<ProductCategories> implements AfterViewInit {

  @ViewChild('tree')
  tree:TreecategoriesComponent;

  items:any[]=[];
 
  
  
 
  constructor(
    private productcategoriesService: ProductcategoriesService,
    private fb1: FormBuilder,
   
    
  ) {
    super(productcategoriesService,fb1);
   }


  ngOnInit(): void {
    
    super.ngOnInit.apply(this, arguments); 
    
    
    
    this.updateList();

    
    
  }

  updateList(){
    this.productcategoriesService.getTree().subscribe((tree)=>{
      
      this.items=tree;
      
    });
  }

  ngAfterViewInit(){
    this.tree.onSaving=(()=>{
      this.updateList();
    });

  }


  add(){
    this.tree.add();
  }





  

 
}
