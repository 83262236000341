import { Injectable } from '@angular/core';
import { WSService } from '../include/service'
import { Globals } from '../globals';
import { Chat } from '../include/structures';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends WSService<Chat>{

  constructor() {super("chat") }

  getInbound(id_user_to){
    return  Globals.ws.requestUrl<Chat[]>("chat","getItems",["id_user_to="+id_user_to+" AND status=1","date DESC"]);
  }

  getOutbound(id_user_from){
    return  Globals.ws.requestUrl<Chat[]>("chat","getItems",["id_user_from="+id_user_from+" AND status=1","date DESC"]);
  }

  getUnReadChats(id_user_from){
    return  Globals.ws.requestUrl<Chat[]>("chat","getItems",["id_user_to="+id_user_from+" AND is_read=0 AND status=1","date DESC"]);
  }
}
