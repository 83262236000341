import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { i } from 'mathjs';

import { Globals } from 'projects/core/src/globals';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, User } from 'projects/core/src/include/structures';
import { OrderingMode } from 'projects/easybeach-backend/src/app/db/ordering';
import { Shops } from '../../../db/shops';
import { Tour } from '../../../db/tour';
import { ShopsService } from '../../../services/shops.service';
import { ToursService } from '../../../services/tours.service';
import { TypesService } from '../../../services/types.service';
import { WizardComponent } from '../wizard/wizard.component';

import { TourDetailComponent } from './tour-detail/tour-detail.component';
import { TourpreviewComponent } from './tourpreview/tourpreview.component';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css']
})
export class ToursComponent extends ModelList<Tour> implements OnInit {
  
  @Output()
  tourSelected:EventEmitter<any>=new EventEmitter<any>();

  @Input()
  filterbox=true;

  @Input()
  autoopen="";

  @Input()
  type=1;

   get user(): User {
      return Globals.user;
  }
  
  filter_status:Filter=new Filter();
  filter_id_shop_start:Filter=new Filter();
  filter_id_type:Filter=new Filter();

  filter_type:Filter<number>=new Filter();

  filter_search:Filter=new Filter();
  selectedRecord:Tour;
  shops:Shops[]=[];
  types=[];

  constructor(
    private toursService: ToursService,
    private fb1: FormBuilder,
    private shopsService:ShopsService,
    private typesService:TypesService
  ) { 
    super(toursService,fb1);
    this.detailViewModal=TourDetailComponent;
  }

  ngOnInit(): void {
    this.title="Tour"

    super.ngOnInit.apply(this, arguments); 
    this.module=Globals.parameters.get("booking");

    let id_default_address=this.module.getParam("id_address",0);


    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("t.title");
    this.filter_search.fields.push("t.subtitle");
    this.filter_search.fields.push("t.code");
    
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_id_shop_start.mode=FilterMode.normal;
    this.filter_id_shop_start.fields=[];
    this.filter_id_shop_start.fields.push("t.id_shop_start");
    this.filter_id_shop_start.value="";
    this.filter.push(this.filter_id_shop_start);

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("t.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    if(!this.user.isBackendUser()){
      this.filter_type.mode=FilterMode.normal;
      this.filter_type.fields=[];
      this.filter_type.fields.push("t.type");
      this.filter_type.value=this.type;
      this.filter.push(this.filter_type);
    }

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("t.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.ordering.field="t.ordering";
    this.ordering.mode=OrderingMode.ascendent;

     //ottieni i punti hitinero
     this.shopsService.getItemsByAddress(id_default_address).subscribe((items)=>{
      this.shops=items;

     });


     this.typesService.getTypes("tour").subscribe((items)=>{
      this.types=items;
     })

    this.getItems();


     this.afterGetItems=()=>{
       
     }

  }

  ngOnChanges(): void{
    if(this.autoopen!=""){
      this.toursService.getTour(this.autoopen).subscribe((result)=>{
        if(result){
         this.openPreview(result);
        }
      })
    }
  }

  getCoverImage(r:Tour){
    
   let t:Tour=new Tour();
   Object.assign(t,r);
   t.parseImages();
   return t.coverImage().imagePath;
    
  }

  parseJSON(value,defaultvalue=[]){
    try{
      return JSON.parse(value);
    }catch{
      return defaultvalue;
    }
  }


  openPreview(record){
    
    Globals.modal.showModal(TourpreviewComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":record.id}
    ],(instance)=>{
      if(instance){
        let tour_selected=instance['tour_selected'];

        if(this.mode=='embedded'){
          this.tourSelected.emit(tour_selected);
        }else{
          Globals.modal.showModal(WizardComponent,[
            {"name":"mode","value":"modal"},
            {"name":"tour_selected","value":tour_selected},
          ],()=>{
  
          }) 
        }
        
      }
    });
  }

  updateOrdering(record){
    this.update(record,()=>{
      record['editOrdering']=false;
      this.getItems();
    })
  }


}
