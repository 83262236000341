import { NgModule } from '@angular/core';
import { ReferenceComponent } from './reference.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [FormsModule,CommonModule],
   exports: [ReferenceComponent],
   declarations: [ReferenceComponent],
   providers: [],
})
export class ReferenceModule{ }