<section>
    <div [class.modal-content]="model.mode=='modal' && showCard">
        <div *ngIf="model.mode=='modal' && showCard" class="modal-header bg-light py-1">
            <h6 class="mb-0">{{model.titleModal}}</h6>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="close()" >
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div [class.modal-body]="model.mode=='modal' && showCard">
            <ng-content #content></ng-content>
        </div>
        <div class="modal-footer py-1" *ngIf="model.mode=='modal' && showCard">
            <button class="btn btn-sm btn-secondary" (click)="close()">Chiudi</button>
        </div>
    </div>
</section>


