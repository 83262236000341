<button class="btn btn-default" (click)="openModalFields()">Seleziona</button>


<ng-template #modalFields>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let r of list" (click)="selectField(r.Field)">
                {{r.Field}}
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalFields()">Annulla</button>
    </div>

</ng-template>