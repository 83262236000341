<div class="row text-xs font-italic">
    <ng-container *ngIf="model">
        <div class="col-12" *ngIf="model.pages.length>1" >
            <ul class="pagination m-1 text-center">
                <li class="btn-xs px-0 page-item {{p.class}}"  *ngFor="let p of model.pages">
                    <button class="page-link" (click)="model.goToPage(p)">{{p.label}}</button>
                </li>
            </ul>
        </div>
    </ng-container>
    <div id="pagination_desc" class="col-12">
        <span class="mr-1"><span class="d-none d-sm-inline-block" *ngIf="model.recordcount!=0">Visualizzat<span *ngIf="model.recordcount==1">o</span><span *ngIf="model.recordcount>1">i</span></span></span>
        <b style="font-size:110%" class="text-primary">{{model.recordcount}}</b>
        <span class="mx-1">elementi \ Mostra</span>
        <select class="bg-light" style="max-width:80px;" [(ngModel)]="model.paging_count" (ngModelChange)="getItems()" >
            <!-- <option value="5">5</option> -->
            <option value="12">12</option>
            <option value="24">24</option>
            <option value="48">48</option>
            <option value="96">96</option>
        </select>
        <span class="d-none d-sm-inline-block mx-1">elementi per pagina</span>
        <span *ngIf="showStatus">
            <span> \ Mostra solo </span>
            <select style="max-width:100px;" [(ngModel)]="model.filter_status.value" (ngModelChange)="getItems()"
                [class.bg-light]="model.filter_status.value==1"
                [class.bg-danger]="model.filter_status.value==2"
                [class.bg-warning]="model.filter_status.value==3"
                >
                <option value="1">Utilizzati</option>
                <option value="2">Cestinati</option>
                <option value="3">Archiviati</option>
            </select>
        </span>
    </div>
</div>