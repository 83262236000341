import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
//import {Modal} from '../../include/modal'
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})

export class ModalComponent implements OnInit {

  @Input() public title = "Information";

  @Input() public description = "";

  //@Input() public child:Modal;

  @Input() public class:string="";

  @Input() public showConfirmButton=true;

  @Input() public showHeader:boolean=true;

  @Input() public showFooter:boolean=true;
  
  @Input() public lblConfirmButton="Ok";
  @Input() public lblCancelButton="Annulla";
  


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
   
  
  
  }

  close(reason="cancel"){
    if(this['modalWindow']){
      this['modalWindow'].close(reason);
    }else{
      this.activeModal.dismiss(reason);
    }
    
//    
  }

  

}
