import { Injectable } from '@angular/core';
import {City,CityEntry,CountryEntry,ZipEntry} from '../db/city';
import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})

export class CitiesService extends WSService<City> {

  constructor() {super("cities") }

  Countries():Observable<CountryEntry[]>{
    return  Globals.ws.requestUrl<CountryEntry[]>("cities","getCountries",[],true,0,1000000000);
  }

  Cities(country:string):Observable<CityEntry[]>{
    return  Globals.ws.requestUrl<CityEntry[]>("cities","getCities",[country],true,0,1000000000);
  }

  Zips(city:string):Observable<ZipEntry[]>{
    return  Globals.ws.requestUrl<ZipEntry[]>("cities","getZips",[city],true,0,1000000000);
  }

}

