<a  class="nav-link" data-toggle="dropdown" href="#"  title="{{list.length}} messaggi">
    <i class="fas fa-comments" [class.blink_me]="list.length>0" [class.text-danger]="list.length>0"></i>
        <small class="ml-1">{{list.length}}</small>
  </a>
<div class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0">
    <div class="card mb-0">
        <div class="card-header text-center p-1 bg-primary">Messaggi</div>
        <div class="card-body">
            <small *ngIf="list.length==0">
                <div class="alert alert-info mb-0" >
                    Non ci sono nuovi messaggi in arrivo!
                </div>
            </small>
            <ul class="list-group" *ngIf="list.length>0">
                <li *ngFor="let m of list" class="list-group-item" (click)="openInBoudMessage=true;openMessageModal(m)">
                    <small>da: <b>{{m.user_from.name}}</b> {{m.date | date:"d/M/Y HH:mm"}}</small><br/>
                    <span class="truncate">{{m.message}}</span>
                </li>
            </ul>
        </div>
        <div class="card-footer">
            <button type="button" class="btn btn-block btn-secondary" (click)="openWriteMessageModal()"><i class="fa fa-pencil-alt mr-2"></i>Scrivi</button>
            <button type="button" class="btn btn-block btn-xs btn-info" (click)="openMessagesModal()">Tutti i messaggi</button>
        </div>
    </div>
</div>

<ng-template #modalMessage>
    <div class="modal-header bg-light">
        da <b>{{message.user_from.name}}</b> a <b>{{message.user_to.name}}</b><br/>
        <small>{{message.date | date:"d/M/Y H:m"}}</small>
    </div>
    <div class="modal-body">
        {{message.message}}
    </div>
    <div class="modal-footer  bg-light">
        <button type="button" class="btn btn-secondary" (click)="closeMessageModal(openInBoudMessage)">Chiudi</button>
    </div>
</ng-template>


<ng-template #modalWriteMessage>
    <div class="modal-header bg-primary">Invia un messaggio</div>
    <div class="modal-body border-top">
        <div class="form-group">
            <label>A</label>
            <div class="input-group input-group-sm">
                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-user"></i></span></div>
                <select class="form-control form-control-sm" name="id_user_to" [(ngModel)]="sendMessage.id_user_to">
                    <ng-container *ngFor="let u of users">
                        <option *ngIf="currentUser.id!=u.id && u.enabled" value="{{u.id}}">{{u.name}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="form-group">
            <label>Messaggio</label>
            <textarea class="form-control form-control-sm" rows="3" name="message" [(ngModel)]="sendMessage.message"></textarea>
        </div>
    </div>
    <div class="modal-footer bg-light">
        <button type="button" class="btn btn-sm btn-secondary " (click)="closeWriteMessageModal()">Annulla</button>
        <button type="button" class="btn btn-sm btn-success " (click)="send()"><i class="far fa-paper-plane mr-2"></i>Invia</button>
    </div>
</ng-template>


<ng-template #modalMessages>
    <div class="modal-header bg-primary">Tutti i messaggi</div>
    <div class="modal-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link " [class.active]="tab==1" (click)="tab=1">Ricevuti</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="profile-tab" [class.active]="tab==2" (click)="tab=2">Inviati</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent" style="min-height:100px">
            <div class="tab-pane fade show active" *ngIf="tab==1">
                <ul class="list-group">
                    <li *ngFor="let m of inbound" class="list-group-item pointer" (click)="openInBoudMessage=true;openMessageModal(m)">
                        <small>da: <b>{{m.user_from.name}}</b> {{m.date | date:"d/M/Y HH:mm"}}</small><br/>
                        <span class="truncate2">{{m.message}}</span>
                        <div *ngIf="m.is_read==0" class="badge badge-warning float-right">Non letto</div>
                    </li>
                </ul>
            </div>
            <div class="tab-pane fade show active" *ngIf="tab==2">
                <ul class="list-group">
                    <li *ngFor="let m of outbound" class="list-group-item pointer" (click)="openInBoudMessage=false;openMessageModal(m)">
                        <small>a: <b>{{m.user_to.name}}</b> {{m.date | date:"d/M/Y HH:mm"}}</small><br/>
                        <span class="truncate2">{{m.message}}</span>
                        <div *ngIf="m.is_read==1" class="badge badge-success float-right">Letto il {{m.lastupdate | date:"d/M/Y HH:mm"}}</div>
                    </li>
                </ul>
            </div>
          </div>
    </div>
    <div class="modal-footer bg-light">
        <button type="button" class="btn btn-sm btn-secondary" (click)="closeMessagesModal()">Chiudi</button>
    </div>
</ng-template>