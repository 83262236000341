import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { HistoryComponent } from './history/history.component';
import { InventoriesDetailsComponent } from './inventories-details/inventories-details.component';
import { InventoriesComponent } from './inventories.component';
import { SalesComponent } from './sales/sales.component';


const routes: Routes = [
  { path: '', component: InventoriesComponent,canActivate:[AuthGuard] },
  { path: 'sales', component: SalesComponent,canActivate:[AuthGuard] },
  { path: 'inventories-details/:id', component: InventoriesDetailsComponent ,canActivate:[AuthGuard]},
  { path: 'inventories-details/:id/:id_product', component: InventoriesDetailsComponent,canActivate:[AuthGuard]},
  { path: 'inventories-details/:id/:id_product/:id_type', component: InventoriesDetailsComponent,canActivate:[AuthGuard]},
  { path: 'history', component: HistoryComponent,canActivate:[AuthGuard]},
  { path: 'history/:product_id_type/:stock_mode', component: HistoryComponent,canActivate:[AuthGuard]},

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoriesRoutingModule { }
