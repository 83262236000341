import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { DocumentTemplate } from '../db/documenttemplate';
import { DocumentsDetailsComponent } from '../views/documents/documents-details/documents-details.component';

@Injectable({
  providedIn: 'root'
})
export class DocumentsTemplateService extends WSService<DocumentTemplate> {

  constructor() {super("documentstemplate") }

  createDocument(table,record,document:Function,paid=0,method_payment="",detailsdocument=[],autosave=true){
    Globals.ws.send("documentstemplate","createDocument",record,[table,"",paid,method_payment,detailsdocument,autosave],document);

  }


  createOpenDocument(table,record,paid=0,method_payment="",detailsdocument=[],oncomplete=null){
    
    this.createDocument(table,record,(document)=>{
      Globals.modal.showModal(DocumentsDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"document_precompiled","value":document}
      ],()=>{
        if(oncomplete)
          oncomplete();
      });
      
    },paid,method_payment,detailsdocument,false);
    
  }



}
