<div class="modal-header">

</div>
<div class="modal-body">
    <table>
        <thead>
            <th></th>
            <th>Campo</th>
        </thead>
        <tbody>
            <tr *ngFor="let c of list">
                <ng-container *ngIf="c.type!='separator'" >
                    <td><input type="checkbox" [(ngModel)]="c.selected" /></td>
                    <td>{{c.label}}</td>
                </ng-container>
                <ng-container *ngIf="c.type=='separator'" >
                    <td colspan="2"><strong>{{c.label}}</strong></td>
                </ng-container>
            </tr>
        </tbody>
    </table> 
</div>
<div class="modal-footer">
    <button class="btn btn-success" (click)="confirm()">Ok</button>
</div>
    

