import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointComponent } from './point.component';
import { FormsModule } from '@angular/forms';
import { Shops } from '../../db/shops';

@NgModule({
  declarations: [
    PointComponent
  ],
  exports:[PointComponent],
  imports: [
    CommonModule,
    
    FormsModule
  ]
})
export class PointModule { }
