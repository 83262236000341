import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { Shops } from 'projects/c1-backend/src/app/db/shops';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { ProductsComponent } from 'projects/c1-backend/src/app/views/products/products.component';
import { ShopsComponent } from 'projects/c1-backend/src/app/views/shops/shops.component';
import { Globals } from 'projects/core/src/globals';
import { Model } from 'projects/core/src/include/model';
import { UploadService } from 'projects/core/src/lib/upload.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Tour } from 'projects/c1-backend/src/app/db/tour';
import { ToursService } from 'projects/c1-backend/src/app/services/tours.service';



@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.css']
})
export class TourDetailComponent extends Model<Tour> implements OnInit {
  
  public EditorIntro = ClassicEditor;
  public EditorDescription = ClassicEditor;
  
  editorOnIntro=true;
  editorOnDescription=true;


  @ViewChild("modalAddCalendar")
  modalAddCalendar:TemplateRef<any>
  
  
  fileToUpload: File = null;
  is_calendar_edit=false;
  calendar_from;
  calendar_to;
  quantity=1;
  min_quantity=1;
  location_start="";
  price_adult=0;
  price_children=0;
  prices=[];
  id_shop_start=0;
  time_start="09:00:00";
  time_end="20:00:00";
  days_week=[];
  ids="";
  
  shops:Shops[];

  get total_cost(){
    let total=0;
    if(Array.isArray(this.record.costs)){
      for(let c of this.record.costs){
        total=total+c['cost']*c['quantity'];
      }
    } 
    
    return total;
  }

  constructor(
    private route1: ActivatedRoute,
    private toursService: ToursService,
    private uploadService:UploadService,
    public shopsService:ShopsService,
    private modalService: NgbModal
  ) { 
    super(route1,toursService);
  }

  ngOnInit(): void {

    this.record=new Tour();
    this.title="Tour";
    super.ngOnInit.apply(this, arguments);
    
    
    this.afterGetItem=(()=>{

      if(this.id==0){
        this.record.id=0;
        this.record.images=[];
        this.record.dates=[];
      }else{
        try{
          this.record.costs=JSON.parse(this.record.costs);
        }catch{
          this.record.costs=[];
        }

        if(!Array.isArray(this.record.costs)){
          this.record.costs=[];
        }
        

        this.getDates();
      }
      
      //parserizza le immagini
      try{
        let images=JSON.parse(this.record.images.toString());
        this.record.images=[];
        for(let i of images){
          let img=new Image();
          Object.assign(img,i);
          this.record.images.push(img);
        }
          
      }catch{
        this.record.images=[];
      }

      

      this.record.parseFeatures();

    });

    this.shopsService.getShops().subscribe((items)=>{
      this.shops=items;
    })

    this.getItem();
  }


  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"tours",(result)=>{

      let i:Image=new Image();
      i.file=result;

      this.record.images.push(i);
      Globals.setLoading(false);
     
    },true);
}

removeImage(img:Image){
  for(let i=0;i<this.record.images.length;i++){
    if(img==this.record.images[i]){
      this.record.images.splice(i,1);
      return;
    }

  }
 
}


/** Conferma la creazione del calendario date del tour nel database */
addDate(){

  if(!this.calendar_from){
    alert("Specificare la data di inizio");
    return;
  }

  if(!this.calendar_to){
    alert("Specificare la data di fine");
    return;
  }

  if(!this.time_start){
    alert("Specificare l'orario di partenza");
    return;
  }

  if(!this.time_end){
    alert("Specificare l'orario di arrivo");
    return;
  }

  if(this.days_week.length==0){
    alert("Specificare in quali giorni della settimana il tour è attivo");
    return;
  }

  if(!this.id_shop_start){
    alert("Specificare il luogo di partenza del tour");
    return;
  }

  if(this.prices.length==0){
    alert("Specificare almeno 1 riga di prezzi");
    return;
  }



  Globals.setLoading(true);

  //se prices è definito, prendi il prezzo minimo tra bambini ed adulti
  let min_price_adult=9999999, min_price_children=99999999;
  for(let p of this.prices){
    if(p['price_adult']<min_price_adult){
      min_price_adult=p['price_adult'];
    }

    if(p['price_children']<min_price_children){
      min_price_children=p['price_children'];
    }
    
  }



  this.toursService.generateDates(this.record.id,this.calendar_from,this.calendar_to,this.time_start,this.time_end,this.id_shop_start,min_price_adult,min_price_children,this.quantity,this.min_quantity,this.location_start,this.days_week,this.prices,this.ids).subscribe((result)=>{
    this.getDates();
    this.closeModalAddCalendar();
    Globals.setLoading(false);
  });

 
}

getDates(){
  this.toursService.getDateGrouped(this.record.id).subscribe((items)=>{
    this.record.dates=items;
    
  });
}

removeDate(d:any){
  this.toursService.deleteDate(d.id_tour,d.date_start,d.date_end,d.time_start,d.time_end,d.id_shop_start).subscribe((result)=>{
    this.getDates();
  });
}

addDates(){
  this.is_calendar_edit=false;

  this.calendar_from="";
  this.calendar_to="";
  this.time_start="09:00:00";
  this.time_end="12:00:00";
  this.days_week=[];
  this.openModalAddCalendar();
}

editDate(d:any){
  this.is_calendar_edit=true;
  this.ids=d.ids;
  this.calendar_from=d.date_start;
  this.calendar_to=d.date_end;
  this.time_start=d.time_start;
  this.time_end=d.time_end;
  this.id_shop_start=d.id_shop_start;
  this.prices=JSON.parse(d.prices);
  this.min_quantity=d.min_quantity;
  this.location_start=d.location_start;
  this.quantity=d.quantity;
  this.price_adult=d.price_adult;
  this.price_children=d.price_children;
  this.days_week=[];
  for(let dw of d.days_of_week.split(',')){
    this.addOrRemoveDayWeek(dw);
  }

  


  this.openModalAddCalendar();
}

selectShop(){
  Globals.modal.showModal(ShopsComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona un punto di partenza"}],(instance)=>{
    let r=instance['recordSelected'];
   this.OnSelectShop(r);


    
    
  });
}

OnSelectShop(r){
  this.record.shop=r;
  this.record.id_shop_start=r.id;
};


clearAllDates(){
  if(confirm("Sicuro di voler cancellare tutte le date programmate?"))
    this.toursService.clearAllDates(this.record.id).subscribe((result)=>{
      this.getDates();
    });
}


addFeature(){

  let f={};
  f['id']=this.record.features.length+1;
  f['feature']="";
  f['icon']="";
  f['value']="";

  this.record.features.push(f)
  
}

deleteFeature(f:any){
  console.log(f);
  for(let i=0;i<this.record.features.length;i++){
    if(this.record.features[i].feature==f.feature){
      this.record.features.splice(i,1);
      return;
    }
  }
}

openModalAddCalendar(){
  this.modalAddCalendar['modal']=this.modalService.open(this.modalAddCalendar,{size:'lg'});
}
closeModalAddCalendar(){
  this.modalAddCalendar['modal'].close("success");
}


addDetail(){
  Globals.modal.showModal(ProductsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
    if(instance!=null){
      const product=instance['recordSelected'];
      let detail={};
      detail['quantity']=1;
      detail['id_product']=product['id'];
      detail['product']=product;
      this.record.details.push(detail);
    }
  });
}


removeDetail(r:any){
  for(let i=0;i<this.record.details.length;i++){
    if(this.record.details[i].id_product==r.id_product){
      this.record.details.splice(i,1);
      return;
    }

  }
 
}

addOrRemoveDayWeek(day){
  let idx=this.days_week.indexOf(day);
  if(idx>-1 && idx!=undefined){
    this.days_week.splice(idx,1);
  }else{
    this.days_week.push(parseInt(day));
  }

}

addAllDayWeek(){
  this.days_week=[];
  this.days_week.push(0);
  this.days_week.push(1);
  this.days_week.push(2);
  this.days_week.push(3);
  this.days_week.push(4);
  this.days_week.push(5);
  this.days_week.push(6);
}

removeAllDayWeek(){
  this.days_week=[];
}

checkDayWeek(day){
  if(this.days_week.indexOf(day)>-1)
    return true;

  return false;
}


replaceDaysOfWeek(days:string){
  days=days.replace("1","Lun");
  days=days.replace("2","Mar");
  days=days.replace("3","Mer");
  days=days.replace("4","Gio");
  days=days.replace("5","Ven");
  days=days.replace("6","Sab");
  days=days.replace("0","Dom");
  return days;
  
}

removePrice(price){
  for(let i=0;i<this.prices.length;i++){
    if(price==this.prices[i]){
      this.prices.splice(i,1);
      return;
    }
  }
}

addPrice(){

  //verifica se l'array è prices è corretto
  if(!Array.isArray(this.prices)){
    this.prices=[];
  }


  //prendi l'ultimo prezzo
  let last_price={};
  last_price['from']=0;
  last_price['to']=0;
  if(this.prices.length>0)
    last_price=this.prices[this.prices.length-1];

  let price={};
  price['from']=last_price['from']+1;
  price['to']=last_price['to']+1;
  price['price_adult']=0;
  price['price_children']=0;
  this.prices.push(price);

}

addCost(){
  let cost={};
  cost['description']="";
  cost['quantity']=1;
  cost['um']="";
  cost['cost']=0;
  this.record.costs.push(cost);
}

removeCost(cost){
  for(let i=0;i<this.record.costs.length;i++){
    if(this.record.costs[i]==cost){
      this.record.costs.splice(i,1);
      return;
    }
  }
}

}
