import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Model } from 'projects/core/src/include/model';
import { Ads } from '../../../ads';
import { AdsService } from '../../../services/ads.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent extends Model<Ads> implements OnInit {

  constructor(
    private route1: ActivatedRoute,
    private adsService: AdsService,
  ) { 
    super(route1,adsService);
  }

  ngOnInit(): void {

    this.title = 'Dettaglio Annuncio';
    super.ngOnInit.apply(this, arguments); 

    this.getItem();
   
  }


  deleteOffer(o){
    if(confirm("Sicuro di voler eliminare l'offerta???")){
      this.adsService.deleteOffer(o.id).subscribe((result)=>{
        this.adsService.getOffers(this.record.id).subscribe((items)=>{
          this.record.offers=items;
        })
        
      });
    }
  }


  unlinkOffer(o){
    if(confirm("Sicuro di voler disassociare questa offerta???")){
      this.adsService.unlinkOffer(o.id).subscribe((result)=>{
       this.record.id_ads_offer=0;
       this.record.state=1;
        
      });
    }
  }

}
