import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'projects/core/src/globals';
import { Chat } from 'projects/core/src/include/structures';
import { ChatService } from 'projects/core/src/lib/chat.service';
import { UserService } from 'projects/core/src/lib/user.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  
  @ViewChild("modalWriteMessage")
  modalWriteMessage:TemplateRef<any>;
  modalWriteMessageRef;

  @ViewChild("modalMessage")
  modalMessage:TemplateRef<any>;
  modalMessageRef;

  @ViewChild("modalMessages")
  modalMessages:TemplateRef<any>;
  modalMessagesRef;
  
  sendMessage:Chat;
  message:Chat;
  
  tab=1;
  list=[];

  users=[];
  inbound=[];
  outbound=[];

  openInBoudMessage=true;

  get currentUser(){
    return Globals.user;
  }

  constructor(
    private chatService:ChatService,
    private modalService:NgbModal,
    private userService:UserService
  ) { }

  ngOnInit(): void {

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    })

    setInterval(()=>{
      this.updateList()
    },5000);
    this.updateList();
  }

  updateList(){
    this.chatService.getUnReadChats(Globals.user.id).subscribe((items)=>{
      
      if(this.list.length!=items.length && items.length>0){
        let audio = new Audio();
        audio.src = "../../../assets/alert.wav";
        audio.load();
        audio.play();
      }
      
      
      this.list=items;

      
    });
  }

  getInbound(oncomplete){
    this.chatService.getInbound(Globals.user.id).subscribe((items)=>{
      this.inbound=items;
      oncomplete();
    })
  }

  getOutbound(oncomplete){
    this.chatService.getOutbound(Globals.user.id).subscribe((items)=>{
      this.outbound=items;
      oncomplete();
    })
  }

  openWriteMessageModal(){

    this.sendMessage=new Chat();
    this.sendMessage.id_user_from=Globals.user.id;

    this.modalWriteMessageRef=this.modalService.open(this.modalWriteMessage);
  }

  closeWriteMessageModal(){
    this.modalWriteMessageRef.close("success");
  }


  openMessageModal(message:Chat){

    this.message=message;
    

    this.modalMessageRef=this.modalService.open(this.modalMessage);
  }

  closeMessageModal(saveRead=false){
    this.modalMessageRef.close("success");
    if(saveRead){
      this.message.is_read=true;
      this.chatService.save(this.message,()=>{
        this.updateList();
      },false,false);
    }

  }


  openMessagesModal(){
    this.getInbound(()=>{
      this.modalMessagesRef=this.modalService.open(this.modalMessages);
    });

    this.getOutbound(()=>{

    });

    
    
  }

  closeMessagesModal(){
    this.modalMessagesRef.close("success");
   
   
  }

  send(){
    this.chatService.save(this.sendMessage,(id)=>{
      this.closeWriteMessageModal();
    });
  }

}
