<app-modal #modal [showFooter]="false" [showHeader]="false">
    <!-- <div class="row">
        <div class="col-12 mb-2">
            <a class="btn btn-outline-primary btn-block">
                <i class="fas fa-qrcode mr-2"></i><b>Lotteria</b>
                <i *ngIf="false" class="fas fa-check text-success ml-2"></i>
                <i *ngIf="true" class="fas fa-exclamation-triangle text-warning ml-2"></i>
            </a>
        </div>
    </div> -->
    <div id="methodpayment_summary" class="row px-3 text-md">
        <div class="col-12 text-center text-sm"><b>RIEPILOGO</b></div>
        <div class="col-7">Da incassare</div>
        <div class="col-5 text-right">{{total | currency:" &euro;&nbsp;"}}</div>
        <div class="col-7">Incassato<i *ngIf="total_remain == 0" class="fas fa-check text-success ml-2"></i></div>
        <div class="col-5 text-right">{{total_paid | currency:" &euro;&nbsp;"}}</div>
        <div class="col-7">
            <span *ngIf="total_remain <= 0">Resto</span>
            <span *ngIf="total_remain > 0">Rimanenza</span>
        </div>
        <div class="col-5 text-right" style="border-top: 1px solid rgba(0,0,0,0.6)">
            <span [class.text-danger]="total_remain>0">{{total_remain | currency:" &euro;&nbsp;"}}</span>
        </div>
    </div>
    <div class="row mt-2" *ngIf="listMethods.length > 0">
        <div class="col-12" >
            <div class="card mb-0">
                <table class="table table-sm mb-0">
                    <thead class="bg-light">
                        <th class="text-left"><small>Metodo</small></th>
                        <th class="text-center"><small>Importo</small></th>
                        <th></th>
                    </thead>
                    <tbody class="bg-white">
                        <tr *ngFor="let m of listMethods">
                            <td class="pt-2 text-left">{{m.methodpayment}}</td>
                            <td class="pt-2 text-center">{{m.amount | currency:" &euro;&nbsp;"}}</td>
                            <td><button class="btn text-danger btn-xs float-right" (click)="removePayment(m)" title="Elimina"><i class="fa fa-trash-alt"></i></button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12" *ngIf="total_remain > 0">
            <div class=""> <!-- card p-2 mb-0 -->
                <!-- <div class="row"> -->
                    <div class="mb-2 text-center">
                        <div id="methodpayment_value" class="text-xs card p-1 mb-0 bg-success">STA VERSANDO
                            <input class="d-none d-lg-block form-control text-xl text-center bg-white" type="number" autofocus #paidInput 
                                (select)="$event.target.select()" (focus)="$event.target.select()"  name="paid" [(ngModel)]="paid"/>
                            <div class="d-block d-lg-none bg-black text-xl text-center">{{paid | currency:"&euro;&nbsp;"}}</div>
                        </div>
                    </div>
                </div>
                <a class="wave btn btn-primary btn-block" (click)="selectMethod('Contanti');"><b>Contanti</b><span class="KeyFunction ml-2 d-none d-lg-inline">(F2)</span></a>
                <a class="wave btn btn-primary btn-block" (click)="selectMethod('POS');"><b>POS</b><span class="KeyFunction ml-2 d-none d-lg-inline">(F4)</span></a>
                <a class="wave btn btn-primary btn-block" (click)="selectMethod('Tickets');"><b>Tickets</b><span class="KeyFunction ml-2 d-none d-lg-inline">(F6)</span></a>
            <!-- </div> -->
        </div>
        <div class="col-12 mt-2" *ngIf="total_remain<=0">
            <a class="wave btn btn-success btn-block text-xl" (click)="confirm()" ><i class="fas fa-print mr-2"></i>Stampa<span class="KeyFunction ml-2 d-none d-lg-inline">(F8)</span></a>
            <!-- <a class="wave btn btn-primary btn-block text-xl"><i class="fas fa-share-alt mr-2"></i>Condividi</a> -->
        </div>
        <div class="col-12 mt-2">
            <a class="wave btn btn-secondary btn-block" (click)="modal.close()"  ><i class="fas fa-times mr-2"></i>Annulla<span class="KeyFunction ml-2 d-none d-lg-inline">(ESC)</span></a>
        </div>
    </div>
    <app-keyboard [showFunctions]="false" (onDigit)="paid=$event" ></app-keyboard>
</app-modal>


