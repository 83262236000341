import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VerifyVATNumberService {

  constructor() { }

  error_message:string;

  /**
	 * Normalizes a PI by removing white spaces.
	 * @param string cf Raw PI, possibly with spaces.
	 * @return string Normalized PI.
	 */
	normalize(cf)
	{
		return cf.replace(/\s/g, "");
	}

	/**
	 * Returns the formatted PI. Currently does nothing but normalization.
	 * @param string cf Raw PI, possibly with spaces.
	 * @return string Formatted PI.
	 */
	format(cf)
	{
		return this.normalize(cf);
	}
	
	/**
	 * Verifies the basic syntax, length and control code of the given PI.
	 * @param string pi Raw PI, possibly with spaces.
	 * @return string Null if valid, or string describing why this PI must be
	 * rejected.
	 */
	validate(pi):boolean
	{
		pi = this.normalize(pi);
		this.error_message=null;
		if( pi.length === 0 )
			this.error_message= "Nessun codice inserito.";
		else if( pi.length !== 11 )
			this.error_message= "Codice minore di 11 cifre.";
		if( ! /^[0-9]{11}$/.test(pi) )
			this.error_message= "Caratteri errati.";
		var s = 0;
		for(var i = 0; i < 11; i++ ){
			var n = pi.charCodeAt(i) - "0".charCodeAt(0);
			if( (i & 1) === 1 ){
				n *= 2;
				if( n > 9 )
					n -= 9;
			}
			s += n;
		}
		if( s % 10 !== 0 )
			this.error_message= "Codice di controllo errato.";
		
		if(this.error_message==null)
			return true;
		else
		return false;
	}
}
