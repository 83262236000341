import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { Tax } from '../db/tax';

@Injectable({
  providedIn: 'root'
})
export class TaxesService extends WSService<Tax> {

  constructor() { super("taxes")}


  
  getTaxes():Observable<Tax[]>{
    return  Globals.ws.requestUrl<Tax[]>("taxes","getTaxes",[]);
    
  }
}
