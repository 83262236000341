<div class="row">
  <div class="col-12">
      <div class="form-group row mb-1">
          <div class="col-12 col-lg-2">
            <label>Indirizzo</label>
          </div>
          <div class="col-12 col-lg-10">
            <input [class.alert-danger]="!address" [readonly]="readonly" [class.is-invalid]="!address" [class.section_disabled]="disabled" type="text" class="form-control form-control-sm" placeholder="..." [(ngModel)]="address" (change)="update()">
        </div>  
      </div>
  </div>
</div>

<div class="form-group row mb-1">
  
  <div class="col-12 col-lg-2">
    <label>Comune</label>
  </div>
  <div class="col-12 col-lg-4">
    <input  [class.alert-danger]="!city" [readonly]="readonly" [class.is-invalid]="!city" [class.section_disabled]="disabled" name="city" type="text" placeholder="..." class="form-control form-control-sm"
     [(ngModel)]="city" (change)="update()" />  
  </div>

  <div *ngIf="!foreign" class="col-12 col-lg-1 text-lg-right">
    <label>Prov.</label>
  </div>
  <div *ngIf="!foreign" class="col-12 col-lg-2 px-lg-0">
    <select [disabled]="disabled || readonly" name="country" [class.alert-danger]="!country" [(ngModel)]="country" (change)="update()" class="form-control form-control-sm">
      <option value="AG">Agrigento (AG)</option>
      <option value="AL">Alessandria (AL)</option>
      <option value="AN">Ancona (AN)</option>
      <option value="AO">Aosta (AO)</option>
      <option value="AQ">L'Aquila (AQ)</option>
      <option value="AR">Arezzo (AR)</option>
      <option value="AP">Ascoli-Piceno (AP)</option>
      <option value="AT">Asti (AT)</option>
      <option value="AV">Avellino (AV)</option>
      <option value="BA">Bari (BA)</option>
      <option value="BT">Barletta-Andria-Trani (BT)</option>
      <option value="BL">Belluno (BL)</option>
      <option value="BN">Benevento (BN)</option>
      <option value="BG">Bergamo (BG)</option>
      <option value="BI">Biella (BI)</option>
      <option value="BO">Bologna (BO)</option>
      <option value="BZ">Bolzano (BZ)</option>
      <option value="BS">Brescia (BS)</option>
      <option value="BR">Brindisi (BR)</option>
      <option value="CA">Cagliari (CA)</option>
      <option value="CL">Caltanissetta (CL)</option>
      <option value="CB">Campobasso (CB)</option>
      <option value="CI">Carbonia Iglesias (CI)</option>
      <option value="CE">Caserta (CE)</option>
      <option value="CT">Catania (CT)</option>
      <option value="CZ">Catanzaro (CZ)</option>
      <option value="CH">Chieti (CH)</option>
      <option value="CO">Como (CO)</option>
      <option value="CS">Cosenza (CS)</option>
      <option value="CR">Cremona (CR)</option>
      <option value="KR">Crotone (KR)</option>
      <option value="CN">Cuneo (CN)</option>
      <option value="EN">Enna (EN)</option>
      <option value="FM">Fermo (FM)</option>
      <option value="FE">Ferrara (FE)</option>
      <option value="FI">Firenze (FI)</option>
      <option value="FG">Foggia (FG)</option>
      <option value="FC">Forli-Cesena (FC)</option>
      <option value="FR">Frosinone (FR)</option>
      <option value="GE">Genova (GE)</option>
      <option value="GO">Gorizia (GO)</option>
      <option value="GR">Grosseto G(R)</option>
      <option value="IM">Imperia (IM)</option>
      <option value="IS">Isernia (IS)</option>
      <option value="SP">La-Spezia (SP)</option>
      <option value="LT">Latina (LT)</option>
      <option value="LE">Lecce (LE)</option>
      <option value="LC">Lecco (LC)</option>
      <option value="LI">Livorno (LI)</option>
      <option value="LO">Lodi (LO)</option>
      <option value="LU">Lucca (LU)</option>
      <option value="MC">Macerata (MC)</option>
      <option value="MN">Mantova (MN)</option>
      <option value="MS">Massa-Carrara (MS)</option>
      <option value="MT">Matera (MT)</option>
      <option value="VS">Medio Campidano (VS)</option>
      <option value="ME">Messina (ME)</option>
      <option value="MI">Milano (MI)</option>
      <option value="MO">Modena (MO)</option>
      <option value="MB">Monza-Brianza (MB)</option>
      <option value="NA">Napoli (NA)</option>
      <option value="NO">Novara (NO)</option>
      <option value="NU">Nuoro (NU)</option>
      <option value="OG">Ogliastra (OG)</option>
      <option value="OT">Olbia Tempio (OT)</option>
      <option value="OR">Oristano (OR)/option>
      <option value="PD">Padova (PD)</option>
      <option value="PA">Palermo (PA)</option>
      <option value="PR">Parma (PR)</option>
      <option value="PV">Pavia (PV)</option>
      <option value="PG">Perugia (PG)</option>
      <option value="PU">Pesaro-Urbino (PU)</option>
      <option value="PE">Pescara (PE)</option>
      <option value="PC">Piacenza (PC)</option>
      <option value="PI">Pisa (PI)</option>
      <option value="PT">Pistoia (PT)</option>
      <option value="PN">Pordenone (PN)</option>
      <option value="PZ">Potenza (PZ)</option>
      <option value="PO">Prato (PO)</option>
      <option value="RG">Ragusa (RG)</option>
      <option value="RA">Ravenna (RA)</option>
      <option value="RC">Reggio-Calabria (RC)</option>
      <option value="RE">Reggio-Emilia (RE)</option>
      <option value="RI">Rieti (RI)</option>
      <option value="RN">Rimini (RN)</option>
      <option value="RM">Roma (RM)</option>
      <option value="RO">Rovigo (RO)</option>
      <option value="SA">Salerno (SA)</option>
      <option value="SS">Sassari (SS)</option>
      <option value="SV">Savona (SV)</option>
      <option value="SI">Siena (SI)</option>
      <option value="SR">Siracusa (SR)</option>
      <option value="SO">Sondrio (SO)</option>
      <option value="TA">Taranto (TA)</option>
      <option value="TE">Teramo (TE)/option>
      <option value="TR">Terni (TR)</option>
      <option value="TO">Torino (TO)</option>
      <option value="TP">Trapani (TP)</option>
      <option value="TN">Trento (TN)/option>
      <option value="TV">Treviso (TV)</option>
      <option value="TS">Trieste (TS)</option>
      <option value="UD">Udine (UD)</option>
      <option value="VA">Varese (VA)</option>
      <option value="VE">Venezia (VE)</option>
      <option value="VB">Verbania (VB)</option>
      <option value="VC">Vercelli (VC)</option>
      <option value="VR">Verona (VE)</option>
      <option value="VV">Vibo-Valentia (VV)</option>
      <option value="VI">Vicenza (VI)</option>
      <option value="VT">Viterbo (VT)</option>
  </select>
  </div>


  <div *ngIf="!foreign" class="col-12 col-lg-1 text-lg-right">
    <label>CAP</label>
  </div>
  <div *ngIf="!foreign" class=" col-12 col-lg-2 pl-lg-0">
    <input  [class.alert-danger]="!zip" [readonly]="readonly" [class.section_disabled]="foreign || disabled" name="zip" [(ngModel)]="zip" 
    [class.is-invalid]="zip && zip.toString().length!=5" maxlength="5" class="form-control form-control-sm" (change)="update()">
   
  </div>
</div>
<!-- 
<div class="form-group row mb-1">
  <div class="col-2">
    <label>Provincia</label>
  </div>

  <div class="col-1">
    <select class="form-control form-control-sm"  placeholder=" ..." [(ngModel)]="country"  (ngModelChange)="getCities($event);update();" >
      <option *ngFor="let c of countries" [ngValue]="c.country">{{c.country}}</option>
    </select>
  </div>

  <div class="col-2">
    <label>Comune</label>
  </div>
  <div class="col-4">
    <select class="form-control form-control-sm"  placeholder=" ..." [(ngModel)]="city"  (ngModelChange)="getZips($event);update();">
      <option *ngFor="let c of cities" [ngValue]="c.city">{{c.city}}</option>
    </select>
  </div>

  <div class="col-1">
    <label>CAP</label>
  </div>
  <div class="col-2">
    <input type="text" class="form-control form-control-sm"  placeholder=" ..." [(ngModel)]="zip" (keyup)="update()">
  </div>
</div>
-->
<div class="row">
  <div class="col-12 col-lg-2" *ngIf="enable_geocoding">
    <div class="form-group float-right">
        <label style="color:transparent;">Button</label><br>
        <button  [disabled]="disabled" class="btn btn-default" (click)="geocoding()" ><i class="fas fa-search-location mr-2"></i>Geocoding</button>
    </div>  
  </div>
</div>
  

