import { Injectable } from '@angular/core';
import { Shops } from '../db/shops';
import { WSService } from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import { GlobalHistogramBinarizer } from '@zxing/library';
import { Globals } from 'projects/core/src/globals';

@Injectable({
  providedIn: 'root'
})

export class ShopsService extends WSService<Shops> {

  constructor() {super("shops") }

  getItemsByAddress(id_address:number):Observable<Shops[]>{
    return Globals.ws.requestUrl<Shops[]>("shops","getItems",["id_address="+id_address.toString()],true,0,1000000);
  }

  getShops():Observable<Shops[]>{
    return Globals.ws.requestUrl<Shops[]>("shops","getItems",["status=1"],true,0,1000000);
    
  }

  


}
