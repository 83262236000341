import { Injectable } from '@angular/core';
import {CustomField} from '../include/structures';
import {WSService} from '../include/service'
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CustomfieldsService extends WSService<CustomField> {

  constructor() {super("customfields") }

  getCustomFields(table:string,excludeSeparator:boolean=false):Observable<CustomField[]>{
    return  Globals.ws.requestUrl<CustomField[]>("customfields","getItems",[(table!=""?"c.table='"+table+"' AND ":"")  +"c.status=1"+(excludeSeparator==true?" AND c.type!='separator'":""),"ordering"],true,0,1000);
    
  }

  

  
}

