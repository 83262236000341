import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { TemplateEmail } from '../include/structures';

@Injectable({
  providedIn: 'root'
})
export class TemplateEmailService extends WSService<TemplateEmail> {

  constructor() {super("templateemail") }

 

}
