<div 
  ngxStripeCardGroup
  [formGroup]="stripeTest"
  [elementsOptions]="elementsOptions"
>
<label>Nome dell'intestatario della carta</label>
  <input class="{{class}}" formControlName="name" required />
  <label>Numero della carta</label>
  <ngx-stripe-card-number  [options]="cardOptions"></ngx-stripe-card-number>
  <label>Data di scadenza</label>
  <ngx-stripe-card-expiry [options]="cardOptions"></ngx-stripe-card-expiry>
  <label>Codice CVC</label>
  <ngx-stripe-card-cvc [options]="cardOptions"></ngx-stripe-card-cvc>

</div>

<button *ngIf="showPayButton" [disabled]="disableButton" class="btn btn-success mt-2" (click)="pay()">{{buttonTitle}}</button>