import { NgModule } from '@angular/core';
import { PreviewnotificationComponent } from './previewnotification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { SettingsModule } from 'projects/core/src/settings/settings.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule,SettingsModule],
   exports: [PreviewnotificationComponent],
   declarations: [PreviewnotificationComponent],
   providers: [],
})
export class PreviewnotificationModule{ }