import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Registration } from '../db/registration';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends WSService<Registration> {

  constructor() {super("registration") }

  register(r:Registration,onComplete:Function){
    Globals.ws.send("registration","register",r,[],onComplete);
  }

  verifyUsername(username:string):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("registration","verifyUsername",[username]);
  }

  getRegisterForm():Observable<any>{
    return Globals.ws.requestUrl<boolean>("registration","getRegisterForm",[]);
  }

  recoverPassword(email:string):Observable<any>{
    return Globals.ws.requestUrl<boolean>("registration","recoverPassword",[email]);
  }
}
