export const OrderingMode={
    ascendent:"ASC",
    discendent:"DESC"

}



export class Ordering{
    field:string;
    mode:string;
}