<app-modal title="Seriali del prodotto" [showConfirmButton]="false">
    
    <div class="row">
        <div class="col-8">
            <i class="{{product.icon}}"></i>
            <h4>{{product.brand}} {{product.name}}</h4>
            <h6>Magazzino <strong>{{product['inventoryname']}}</strong></h6>
            <label>Lista dei seriali del prodotto disponibili in questo magazzino</label>
        </div>
        <div class="col-4">
            <ul class="list-group" *ngFor="let s of product.serials">
                <ng-container *ngIf="s.avaible>0"><li class="list-group-item">{{s.sn}}</li></ng-container>
                
            </ul>
        </div>

        
    </div>
</app-modal>