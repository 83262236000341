import { NgModule } from '@angular/core';
import { TreecategoriesComponent } from './treecategories.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';


@NgModule({
    imports: [FormsModule,CommonModule,ModalModule],
   exports: [TreecategoriesComponent],
   declarations: [TreecategoriesComponent],
   providers: [],
})
export class TreecategoriesModule{ }