import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentDetail } from 'projects/c1-backend/src/app/db/documents';
import { Image } from 'projects/c1-backend/src/app/db/image';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { AddressesService } from 'projects/c1-backend/src/app/services/addresses.service';
import { DocumentsService } from 'projects/c1-backend/src/app/services/documents.service';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { AddressComponent } from 'projects/c1-backend/src/app/views/address/address.component';
import { DocumentlistComponent } from 'projects/c1-backend/src/app/views/documents/documentlist/documentlist.component';
import { Globals } from 'projects/core/src/globals';
import { Model } from 'projects/core/src/include/model';
import { Booking, BookingDetail } from '../../../db/booking';
import { CheckoutComponent } from '../checkoutbooking/checkoutbooking.component';
import { AddressDetailsComponent } from 'projects/c1-backend/src/app/views/address/address-details/address-details.component';
import { MethodpaymentsService } from 'projects/c1-backend/src/app/services/methodpayments.service';
import { BookingService } from '../../../services/booking.service';
import { Extension } from 'projects/core/src/include/parameters';
import { CheckinComponent } from '../checkinbooking/checkinbooking.component';
import { ToursComponent } from '../tours/tours.component';
import { DocumentsTemplateService } from '../../../services/documents-template.service';
import { Helper } from 'projects/core/src/helper';


@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.css']
})
export class BookingDetailComponent  extends Model<Booking> implements OnInit {

  @Input()
  date:string=Helper.convertDateControl();

  @Input()
  adults:number=1;

  @Input()
  id_address:number=0;


  @ViewChild("documentlist")
  documentList:DocumentlistComponent;

  module:Extension;
  shops=[];
  methodpayments=[];
  total;
  field_required=["id_address"];

  get user(){
    return Globals.user;
  }


  constructor(
    private route1: ActivatedRoute,
    private bookingService: BookingService,
    private methodpaymentsService:MethodpaymentsService,
    public addressesService:AddressesService,
    private shopsService:ShopsService,
    private documentsService:DocumentsService,
    private documentTemplateService:DocumentsTemplateService
  ) { 
    super(route1,bookingService);
  }

  ngOnInit(): void {
    this.record=new Booking();
    this.title="Prenotazione";
    super.ngOnInit.apply(this, arguments); 

    this.record.state=1;
    this.record.methodpayment="Contanti";
    this.module=Globals.parameters.get("booking");
    let id_address=this.module.getParam("id_address",0);

    this.afterGetItem=()=>{
      if(this.id==0){
        if(this.id_address>0){
          this.record.id_address=this.id_address;
          this.addressesService.getItem(this.record.id_address).subscribe((item)=>{
            this.record.addressItem=item;
          })
        }
    
        this.record.date_start=this.date;
        this.record.date_end=this.date;
        this.record.adults=this.adults;
        this.record.shop_start=this.shops[0];
        this.record.shop_end=this.shops[0];
        
      } else {
        this.title="Prenotazione "+this.record.code;
      }
      
      for(let d of this.record.details){
        let p:Product=new Product();
        Object.assign(p,d.product);
        d.product=p;
      }

      this.calculateTotal();

    };


    this.afterSave=()=>{
      if(this.record.state>1){

        if(this.record.state>3 && this.record.state!=6){
          if(this.record.checkin=="0000-00-00 00:00:00" || this.record.checkin==undefined ){
            alert("La prenotazione non presenta checkin!\nVerrà aperta la finestra di registrazione checkin.");
            this.checkin();
            //return false;
          }
        }

        if(this.record.state>4 && this.record.state!=6){
          if(this.record.checkout=="0000-00-00 00:00:00" || this.record.checkout==undefined){
            alert("La prenotazione non presenta checkout!\nVerrà aperta la finestra di registrazione checkout.");
            this.checkout();
            //return false;
          }
        }
        
        if (this.documentList.list.length==0){
          this.getItem();
          if(this.record.type_address==0){
            alert("La prenotazione è senza documento fiscale!\nVerrà aperta la finestra per la creazione della fattura.");
            this.addDocument();
            return false;
          }
            return true;
        }
      }
      return true;
    };

    this.shopsService.getShops().subscribe((items)=>{
      this.shops=items;
      
      this.methodpaymentsService.getMethods().subscribe((items_m)=>{
        this.methodpayments=items_m;
        this.getItem();
      });


      // Se nuova prenotazione imposta prelievo e restituzione sullo shop dell'user
      if (!this.record.id_address) {
        
        this.shopsService.getItemsByAddress(id_address).subscribe((items)=>{
          this.shops=items;
          if(this.shops.length>0){
            this.record.shop_start=this.shops.filter(x=>x.id==Globals.user['shop'].id)[0];
            this.record.shop_end=this.shops.filter(x=>x.id==Globals.user['shop'].id)[0];
          }
          
        });
      };
      
    });

   
  }

 

  selectAddress(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona un'anagrafica"}],(instance)=>{
      let r=instance['recordSelected'];

      this.OnSelectAddress(r);


      
      
    });
  }

  OnSelectAddress(r){
    if(r){
      this.record.addressItem=r;
      this.record.id_address=r.id;
    }
  };

  OnViewAddress(r){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":r.id}
    ],(instance)=>{
      if(instance!=null)
        this.OnSelectAddress(instance['record']);
    });
  }


  selectSupplier(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"},{"name":"title","value":"Seleziona un'anagrafica"}],(instance)=>{
      let r=instance['recordSelected'];
      this.OnSelectSupplier(r);
    });
  }

  OnSelectSupplier(r){
    if(r){
      this.record.supplierItem=r;
      this.record.id_supplier=r.id;
    }
  };

  OnViewSupplier(r){
    Globals.modal.showModal(AddressDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":r.id}
    ],(instance)=>{
      if(instance!=null)
        this.OnSelectSupplier(instance['record']);
    });
  }

  confirm(){
    /*
    Globals.modal.showModal(ConfirmbookingComponent,[{"name":"booking","value":this.record}],()=>{},"md");
    */
    this.onBack();
  }

  add(product){
    let bd=new BookingDetail();
    bd.id=0;
    bd.id_booking=this.record.id;
    bd.id_inventory=this.record.shop_start.id_inventory;
    bd.id_product=product.id;
    bd.product=product;
    bd.quantity=1;
    bd.price=0;
    bd.discount=0;
    bd.tax=product.tax;
    this.record.details.push(bd);
    this.calculateTotal();

  }

  remove(record){
    for(let i=0;i<this.record.details.length;i++){
      if(record==this.record.details[i]){
        this.record.details.splice(i,1);
        return;

      }
    }
  }

  calculateTotal(){

    this.record.amount=0;
    this.record.tax=0;
    this.record.taxWithoutDiscount=0;
    this.record.netWithTax=0;
    this.total=0;
    this.record.discountValue=0;
    let total_net=0;
    //this.record.adults=0;
    //this.record.children=0;
    
      for(let d of this.record.details){
        //if(this.record.discount)
          //d.discount=this.record.discount; //associa lo sconto su ogni oggetto selezionato
        let net=d.price*d.quantity*this.record.duration;
        this.record.amount= this.record.amount+net;
        this.record.discountValue=this.record.discountValue+net*(d.discount/100);
        this.record.tax=this.record.tax+net*(1-d.discount/100)*(d.tax/100);
        this.record.taxWithoutDiscount=this.record.taxWithoutDiscount+net*(d.tax/100);
        this.record.netWithTax=this.record.netWithTax+net*(1+d.tax/100);
        /*
        //verifica il conteggio tra numero di adulti e bambini
        if(d.product.tags.includes("adult"))
          this.record.adults=this.record.adults+d.quantity;

        if(d.product.tags.includes("child"))
          this.record.children=this.record.children+d.quantity;
        */
      
      }

      
      this.total=parseFloat((this.record.amount-this.record.discountValue+this.record.tax).toFixed(2));

      //calcola il valore percentuale dello sconto
      
      this.record.discount=(1-(this.total/(this.record.netWithTax)))*100;

      
      
   
  }

  getImagePath(i){
    let image=new Image();
    Object.assign(image,i);
    return image.imagePath;

    
  }


  addDocument(oncomplete=null){

    let details:DocumentDetail[]=[];
    for(let d of this.record.details){
      let dd:DocumentDetail=new DocumentDetail();
      dd.id_product=d.id_product;
      dd.product=d.product;
      dd.description=d.product.title?d.product.title:(d.product.brand?d.product.brand+" ":"")+d.product.name;
      let type=this.record.id_tour==1?"Tour":"Noleggio";
      dd.note=type+" dal "+this.record.dateStart+" "+this.record.time_start+" al "+this.record.dateEnd+" "+this.record.time_end;
      dd.netprice=d.price*this.record.duration;
      dd.quantity=d.quantity;
      dd.discount1=d.discount;
      dd.discount2=0;
      dd.discount3=0;
      dd.tax=d.tax;
      details.push(dd);
    }

    let drecord={};
    Object.assign(drecord,this.record);
    drecord['details']=details;

    this.documentTemplateService.createOpenDocument("booking",drecord,this.total,this.record.methodpayment,this.record.details,()=>{
        this.documentList.updateList();
        if(oncomplete)
          oncomplete();
    });
   


    /*
    //crea il dettaglio fattura
    let details:DocumentDetail[]=[];
    for(let d of this.record.details){
      let dd:DocumentDetail=new DocumentDetail();
      dd.id_product=d.id_product;
      dd.product=d.product;
      dd.description=d.product.title?d.product.title:(d.product.brand?d.product.brand+" ":"")+d.product.name;
      let type=this.record.id_tour==1?"Tour":"Noleggio";
      dd.note=type+" dal "+this.record.dateStart+" "+this.record.time_start+" al "+this.record.dateEnd+" "+this.record.time_end+")";
      dd.netprice=d.price*this.record.duration;
      dd.quantity=d.quantity;
      dd.discount1=d.discount;
      dd.discount2=0;
      dd.discount3=0;
      dd.tax=d.tax;
      details.push(dd);
    }

    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"typedocument","value":1},
      {"name":"mode","value":"modal"},
      {"name":"id_type","value":1},
      {"name":"id_address","value":this.record.id_address},
      {"name":"description","value":this.record.id_tour>0?"Tour":"Noleggio"},
      {"name":"id_documentrule","value":this.record.id_tour>0?Globals.parameters.get("booking").getParam("id_documentrule_tour"):Globals.parameters.get("booking").getParam("id_documentrule_bike")},
      {"name":"details","value":details},
      {"name":"referenceprefix","value":this.record.id_tour>0?Globals.parameters.get("booking").getParam("referenceprefix_tour"):Globals.parameters.get("booking").getParam("referenceprefix_bike")},
      {"name":"id_table","value":this.record.id},
      {"name":"table","value":"booking"},
      {"name":"referencedocument","value":this.record},
      {"name":"methodpayment","value":this.record.methodpayment},
      {"name":"autoUnload","value":false},
      
    ],()=>{
      this.documentList.updateList();
      if(oncomplete)
        oncomplete();
    });
    */
  }

  linkDocument(){
    Globals.modal.showModal(DocumentlistComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        let d=instance['recordSelected'];
        if(confirm("Attribuire il documento scelto a questa prenotazione?")){
          d.id_table=this.record.id;
          d.table="booking";
          this.documentsService.save(d,()=>{
            this.documentList.updateList();
          });
        }
        
      }
    });
  }

  
  calculateNet(event,d){
    const currentGross=parseFloat(event.target.value);
    d.price=currentGross/((1-d.discount/100)*(1+d.tax/100)*d.quantity)/this.record.duration;
    this.calculateTotal();
    
  }


  selectTour(){
    Globals.modal.showModal(ToursComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      this.record.tour=instance['recordSelected'];
      this.record.id_tour=this.record.tour.id;
      
    })
  }

  checkout(){
    Globals.modal.showModal(CheckoutComponent,[{"name":"booking","value":this.record}],(instance)=>{
      if(instance)
        this.getItem();
    });
  }

  checkin(){
    Globals.modal.showModal(CheckinComponent,[{"name":"booking","value":this.record}],(instance)=>{
      if(instance)
        this.getItem();
    });
  }

  openImage(i){
    window.open(this.getImagePath(i),"_blank");
  }

  goToPayment(){
    this.addDocument(()=>{
      this.record.state=2;
    });
  }

  changeState(){
    if(this.record.state==1 && this.record.type_address==1) this.record.state=6;
    if(this.record.state==6 && this.record.type_address==0) this.record.state=1;
  }

  cancelBooking(){
   
      if(confirm("Sicuro di voler annullare la prenotazione?")){
        
        this.record.state=3;
        this.bookingService.save(this.record,()=>{
          alert("Prenotazione annullata");
          this['modalWindow'].close("success");
        });
      }
    
  }


}
