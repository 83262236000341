import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentTemplate } from 'projects/c1-backend/src/app/db/documenttemplate';
import { DocumentsTemplateService } from 'projects/c1-backend/src/app/services/documents-template.service';
import { DocumentsService } from 'projects/c1-backend/src/app/services/documents.service';
import { TaxesService } from 'projects/c1-backend/src/app/services/taxes.service';
import { Globals } from 'projects/core/src/globals';
import { Model } from 'projects/core/src/include/model';
import { ProductsComponent } from '../../../products/products.component';

@Component({
  selector: 'app-documents-template-detail',
  templateUrl: './documents-template-detail.component.html',
  styleUrls: ['./documents-template-detail.component.css']
})
export class DocumentsTemplateDetailComponent extends Model<DocumentTemplate> implements OnInit {
  rules=[];
  taxes=[];

  constructor(
    private route1: ActivatedRoute,
    private documentstemplateService: DocumentsTemplateService,
    private documentsService:DocumentsService,
    private taxesService:TaxesService

  ) {
    super(route1,documentstemplateService);
   }

  ngOnInit(): void {

    super.ngOnInit.apply(this, arguments); 
    this.record=new DocumentTemplate();
    
    this.afterGetItem=()=>{
      if(!Array.isArray(this.record.conditions)){
        try{
          this.record.conditions=JSON.parse(this.record.conditions);
        }catch{
          this.record.conditions=[];
        }
        
      }


      if(!Array.isArray(this.record.details)){
        try{
          this.record.details=JSON.parse(this.record.details);
        }catch{
          this.record.details=[];
        }
        
      }
    }

    this.taxesService.getTaxes().subscribe((items)=>{
      this.taxes=items;
    })
    
    
    this.getRules(()=>{
      this.getItem();
    });
  }


  getRules(oncomplete=null){
    this.documentsService.getRules(this.record.typedocument).subscribe((items_r)=>{
      this.rules=items_r;
      if(this.rules.length>0)
        if(this.record.id_documentsrule==0)
          this.record.id_documentsrule=this.rules[0].id;
        if(oncomplete)
          oncomplete();
    });

  }

  setRule(event){

    //trova la regola nell'elenco
    for(let r of this.rules){
      if(parseInt(r.id)==parseInt(event.target.value.split(":")[1])){
        this.record.documentsrule=r;
        return;
      }
    }

    
  }

  addCondition():void{
    let c={};;
    if(this.record.conditions==undefined)
      this.record.conditions=[];

    this.record.conditions.push(c);
  }

  removeCondition(c):void{
    for(let i=0;i<this.record.conditions.length;i++)
      if(this.record.conditions[i]==c)
        this.record.conditions.splice(i,1);
  }


  addDetail():void{
    let c={};;
    if(this.record.details==undefined)
      this.record.details=[];


    Globals.modal.showModal(ProductsComponent,[
      {"name":"mode","value":"modal"}
      
    ],(instance)=>{
      if(instance){
        let r=instance["recordSelected"];
        c['id_product']=r.id;
        c['description']=r.brand+" "+r.name;
        c['quantity']=1;
        c['unit']=r.unit;
        c['netprice']=0;
        c['tax']=r.tax;
        c['id_tax']=0;
        this.record.details.push(c);
      }
    })



    
  }

  removeDetail(c):void{
    for(let i=0;i<this.record.details.length;i++)
      if(this.record.details[i]==c)
        this.record.details.splice(i,1);
  }

}
