
<div class="modal-header">
    <h4>Scarica prodotti</h4>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancel()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12" style="height: 400px;overflow-y: scroll;">
            <table class="table table-stripped table-sm" >
                <thead>
                    <th>Descrizione</th>
                    <th>Sn</th>
                    <th>Qt</th>
                    <th>P.U. <small>IVA escl.</small></th>
                    <th>IVA</th>
                    <th>Totale</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let p of list">
                        <td width="50%">{{p.description}}</td>
                        <td><span class="badge badge-info" *ngIf="p.sn">{{p.sn}}</span></td>
                        <td width="100px"><input class="form-control form-control-sm" type="number" min="1" step="1" name="quantity" [(ngModel)]="p.quantity" (keyup)="calculateTotal()" (change)="calculateTotal()"/></td>
                        <td><input class="form-control form-control-sm" type="number" min="1" step="0.01" name="netprice" [(ngModel)]="p.netprice" (keyup)="calculateTotal()" (change)="calculateTotal()"/></td>
                        <td width="100px"><input class="form-control form-control-sm" type="number" min="1" step="1" name="tax" [(ngModel)]="p.tax" (keyup)="calculateTotal()" (change)="calculateTotal()"/></td>
                        <td>{{p.netprice*(1+p.tax/100)*p.quantity | currency : '&euro; '}}</td>
                        <td>
                            <button class="btn btn-sm btn-danger" (click)="remove(p)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{total | currency : '&euro; '}}</strong></td>
                    </tr>
                </tfoot>
            </table>
        </div>
        
        <div class="col-8">
            <app-searchproduct  [showDescription]="false" [resetOnEnter]="true" [code]="" [description]="" (onEntrySelected)="add($event)"></app-searchproduct>
        </div>
        <div class="col-2">
            <div class="form-group">
                <label>Qt</label>
                <div class="input-group">
                    <input type="number" min="1" step="1" class="form-control form-control-sm pr-0"  name="quantity" placeholder="..." [(ngModel)]="quantity" >
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" [disabled]="list.length==0" (click)="confirm()">Conferma</button>
    <button class="btn btn-secondary mr-1" (click)="cancel()">Annulla</button>
</div>

