import { Injectable } from '@angular/core';
import { Alarm ,AlarmResult} from '../include/structures';
import { WSService } from '../include/service'
import { Globals } from '../globals';





@Injectable({
  providedIn: 'root'
})
export class AlarmsService extends WSService<Alarm> {

  constructor() { super("alarms")};

  getAlarms(){
    return  Globals.ws.requestUrl<AlarmResult[]>("alarms","getAlarms",[]);
  }
}
