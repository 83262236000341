import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterdateComponent } from './filterdate.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    FilterdateComponent
  ],
  exports:[FilterdateComponent],
  imports: [
    CommonModule, FormsModule
  ]
})
export class FilterdateModule { }
