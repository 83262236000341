import { Injectable } from '@angular/core';
import { WSService } from 'projects/core/src/include/service';
import { Company } from '../company';

@Injectable({
  providedIn: 'root'
})

export class CompaniesService extends WSService<Company> {

  constructor() {super("ticketcompanies") }

  
}

