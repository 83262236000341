import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { CashFlowDetailsComponent } from './cash-flow-details/cash-flow-details.component';

import { CashFlowContainerComponent } from './cash-flow.component';

const routes: Routes = [
  { path: '', component: CashFlowContainerComponent,canActivate:[AuthGuard] },
  //{path: 'cash-flow-details/:id/:id_address', component: CashFlowDetailsComponent,canActivate:[AuthGuard]},
  //{path: 'cash-flow-details/:id', component: CashFlowDetailsComponent,canActivate:[AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CashFlowRoutingModule { }
