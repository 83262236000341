import {Type} from "./type"
import {Shops} from "./shops"
import {Deadlines} from './deadlines';
import { Contracts } from './contracts';
import { Address } from './address';
import { Activities } from './activities';
import { Note } from './note';
import { Product } from "./product";
import { CustomField } from "projects/core/src/include/structures";

export class Installation {
    id:number;
    id_shop:number;
    shop:Shops;
    id_type: number;
    type:Type;
    brand:string;
    model:string;
    id_product:number;
    sn:string;
    matricola:string;
    code:string;
    status:number;
    customfields:CustomField[];
    category:string;
    id_category:number;
    nextDeadlines:Deadlines;
    nextDeadline:Deadlines;
    nextActivities:Activities;
    contract:Contracts;
    contracts:Contracts[];
    id_reseller:number;
    reseller:Address;
    loguseralert:number;
    notes:Note[];
    product:Product;
    history:any[];
    last_log:any;
    fields=[];
}


export class InstallationLog {
    id:number;
    sn:string;
    date:string;
    km:number;
    price:number;
    refueling:number;
    id_user:number;
    status:number;
    username:string;
    created_from:number;
    shopname:string;
    latitude:number;
    longitude:number;
}