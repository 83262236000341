import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode } from 'projects/core/src/include/structures';
import { Project } from '../../db/project';
import { ProjectsService } from '../../services/projects.service';
import { ProjectDetailComponent } from './project-detail/project-detail.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent extends ModelList<Project>  implements OnInit {

  filter_status:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_created_from:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_phase:Filter<number>=new Filter<number>();

  isFilterSet:boolean=false;
  
  date_from: Date=new Date();
  date_to: Date=new Date();
  shops=[];
  total=0;
  total_cost=0;
  total_paid=0;
  @Input()
  id_address:number=0;

  @Input()
  filterbox:boolean=true;

  constructor(
    private projectsService: ProjectsService,
    private fb1: FormBuilder,
  ) {
    super(projectsService,fb1);
    this.detailViewModal=ProjectDetailComponent;
   }

  ngOnInit(): void {
    this.title='Progetti';
    super.ngOnInit.apply(this, arguments); 

    const default_address=Globals.parameters.get("general").getParam("default_address");

   
    if(this.mode!="embedded"){
      this.filter_date.mode=FilterMode.date;
      this.filter_date.fields=[];
      this.filter_date.value=Helper.convertDateControl(this.date_from);
      this.filter_date.value2=Helper.convertDateControl(this.date_to);
      this.filter_date.fields.push("p.date");
      this.filter.push(this.filter_date);
    }

    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList(this.id_address);


    this.afterGetItems=()=>{
      this.total=0;
      this.total_cost=0;
      this.total_paid=0;
      for(let i of this.list){
        this.total=this.total+parseFloat(i.amount.toString());
        this.total_cost=this.total_cost+parseFloat(i.cost.toString());
        this.total_paid=this.total_paid+parseFloat(i.paid.toString());
      }
    }

  }

  setFilters():void{
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("p.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("p.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);

    this.filter_phase.mode=FilterMode.normal;
    this.filter_phase.fields=[];
    this.filter_phase.fields.push("p.phase");
    this.filter_phase.nullvalue=0;
    this.filter_phase.value=0;
    this.filter.push(this.filter_phase);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("addr.name");
    this.filter_search.fields.push("p.name");
    this.filter_search.fields.push("p.note");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    if(this.mode!="modal" && this.mode!="embedded")
    Globals.navigation.getStateFilters(this.constructor.name+this.instance,this.filter);

    this.ordering.field="p.date";
    this.ordering.mode=OrderingMode.discendent;  
    this.isFilterSet=true;

  }

  ngOnChanges():void{
    if(this.id_address>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }

  updateList(id_address:number=0):void{
    if(!this.isFilterSet)
        this.setFilters();
  
    
    if(id_address>0){
      this.filter_id_address.value=id_address.toString();
    }
  
    this.getItems();
  }

}
