import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { DocumentsComponent } from './documents.component';
import { DocumentsDetailsComponent } from './documents-details/documents-details.component';


const routes: Routes = [
  { path: '', component: DocumentsComponent,canActivate:[AuthGuard] },
  //{ path: 'documents-detail/:id', component: DocumentsDetailsComponent ,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
