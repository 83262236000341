import { Input, Component, OnInit,ViewChild,TemplateRef, ElementRef} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ActivatedRoute } from '@angular/router';
import { InventoriesDetails, Inventories, InventoryMode } from '../../../db/inventories';
import { Type } from '../../../db/type';
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { ProductsService } from '../../../services//products.service';
//import { InstallationsComponent } from '../../../installations/installations.component';
import { UserService } from 'projects/core/src/lib/user.service';
import {Product,Pricelist} from '../../../db/product';
import { User } from 'projects/core/src/include/structures';
import { Globals } from 'projects/core/src/globals';
import { AddressComponent } from '../../address/address.component';
import { ProductsComponent } from '../../products/products.component';
import { AddressesService } from '../../../services/addresses.service';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
export interface Total{
  net:number;
  discount:number;
  tax:number;
  gross:number;
  weight:number;
}



@Component({
  selector: 'app-inventories-details',
  templateUrl: './inventories-details.component.html',
  styleUrls: ['./inventories-details.component.sass']
})

export class InventoriesDetailsComponent extends Model<InventoriesDetails>  implements OnInit {
  
  @ViewChild('quantity')
  quantity:ElementRef;


  modulename="inventories";
  @Input()
  id_product:number=0;

  @Input()
  sn:string="";

  @Input()
  id_table:number=0;

  @Input()
  table:string="";

  id_type:number=1;
  type_inventories:Type[];

  total:Total;
  
  inventories:Inventories[];
  users:User[];
  detailForm;
  field_required=['date','id_product','id_inventory'];

  pricelist:Pricelist[];
  cost:number;

  serialsToLoad:string;

  constructor(
    private route1: ActivatedRoute,
    private inventoriesService: InventoriesDetailsService,
    private productsService:ProductsService,
    private userService:UserService,
    public addressesService:AddressesService
   
    
    ) {
      super(route1,inventoriesService); 
      this.total={} as Total;
      this.total.net=0;
      this.total.discount=0;
      this.total.gross=0;
      this.total.tax=0;
      this.total.weight=0;
     
  }



  ngOnInit(): void {
    
    super.ngOnInit.apply(this, arguments); 

    //carica i parametri
    if(this.mode!="modal"){
      this.route1.params.subscribe((params) =>{

      this.id = params.id;
      if(params.id_product>0)
        this.id_product=params.id_product;
        
        if(params.id_type>0)
          this.id_type=params.id_type;
      
      } );
    }

    this.inventoriesService.getInventories().subscribe((items)=>{
      this.inventories=items;
      if(this.inventories.length>0)
        this.record.id_inventory=this.inventories[0].id;

        this.userService.getUsers().subscribe((items)=>{
          this.users=items;
          this.getItem();
        });

     
   });



   this.afterGetItem=(()=>{
    this.title = 'Dettaglio '+(this.id_type==1?"Carico":"Scarico");
    if(this.id==undefined || this.id==0 ){
      this.record=new InventoriesDetails();
      //cerca l'anagrafica corretta
      this.record.type=this.id_type;
      this.record.id_table=this.id_table;
      this.record.table=this.table;
      if(this.id_product>0){
        this.productsService.getItem(this.id_product).subscribe((item)=>{
        this.record.id_product=item.id;
        this.record.sn=this.sn;
        this.record.product=item;
        this.record.description=item.brand+" "+item.name;
        this.record.unit=item.unit;
        this.record.netprice=item.netcost;
        this.record.tax=item.tax;
       

        this.calculateGrossDetail();
        });
      }else{
        this.selectProduct();
      }

      
     
    }else{
      this.id_type=this.record.type;
    }
    this.calculateGrossDetail();
    
    
   });

   this.afterSelectionRecord=((r,table)=>{
    
   });

   
  }


  calculateGrossDetail(){
    
    this.record.gross=Math.round(
      this.record.netprice*
      this.record.quantity*
      (1+this.record.tax/100)*
      (1-this.record.discount1/100)*
      (1-this.record.discount2/100)*
      (1-this.record.discount3/100)*
      100)/100;
  }

  calculateNetDetail(){
    this.record.netprice=Math.round(
      (this.record.gross/this.record.quantity)/
      (1+this.record.tax/100)/
      (1-this.record.discount1/100)*
      (1-this.record.discount2/100)*
      (1-this.record.discount3/100)*
      100)/100;
  }
/*
  searchIntoInstallations(){
    
    
    Globals.modal.showModal(InstallationsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      let r=instance['recordSelected'];
      this.record.sn=r['sn'];
    });
    
  }
*/

  getProduct(event:Product){

    this.record.product=event;
    this.record.id_product=event.id;
    this.record.description=event.brand+' '+event.name;
    this.record.netprice=this.record.product.getPrice(this.record.quantity,this.record.type);
    this.record.tax=event.tax;
    this.record.gross=event.grossprice;
    this.record.unit=event.unit;
    this.record.sn=event.sn;
    if(this.record.type==InventoryMode.load){
      //calcolo lo sconto equivalente
      //this.record.discount=this.record.product.getEquivalentDiscountCost();

      this.record.discount1=this.record.product.discountcost1;
      this.record.discount2=this.record.product.discountcost2;
      this.record.discount3=this.record.product.discountcost3;
    }

    

    this.calculateGrossDetail();
   
  }
  
  setPrice(){
    
    
    this.record.netprice=this.record.product.getPrice(this.record.quantity,this.record.type);
  }

  selectAddress(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      this.record.addressItem=instance['recordSelected'];
      this.record.id_address=this.record.addressItem.id;
      
    })
  }


  addAddress(){
    Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        this.record.addressItem=instance['record'];
        this.record.id_address=this.record.addressItem.id;
      }
    })
  }


  selectProduct(){
    Globals.modal.showModal(ProductsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null){
        let r:Product=new Product();
        Object.assign(r,instance['recordSelected']);
        this.getProduct(r);
        setTimeout(()=>{ // this will make the execution after the above boolean has changed
          this.quantity.nativeElement.focus();
          this.quantity.nativeElement.select();
        },0);  
        

      } 
      
    });
  }

}
