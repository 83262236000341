import { Component, OnInit,Input } from '@angular/core';
import { Modal } from 'projects/core/src/include/modal';
import { Deadlines } from '../../../db/deadlines';
import { Type } from '../../../db/type';
import { TypesService } from '../../../services/types.service';

@Component({
  selector: 'app-confirmdeadline',
  templateUrl: './confirmdeadline.component.html',
  styleUrls: ['./confirmdeadline.component.sass']
})
export class ConfirmdeadlineComponent extends Modal implements OnInit {

  constructor( private typesService: TypesService,) {
    super();
   }

   type_deadline:Type[];
   title:string;
  record:Deadlines; 
  actionNextDate:number=1;
  

  ngOnInit(): void {
    
    this.typesService.getTypes("deadlines").subscribe((items)=>{
      this.type_deadline=items;
   });
  }


 
}
