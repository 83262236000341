<app-modal title="Nuova date di agenda">
    <table class="table table-sm">
        <thead>
            <th>Voce</th>
            <th>Data</th>
            <th>Tipo</th>
        </thead>
        <tbody>
            <tr *ngFor="let r of list">
                <td>{{r.description}}</td>
                <td><input type="date" class="form-control form-control-sm" name="r.description" [(ngModel)]="r.date"></td>
                <td>
                    <ng-container *ngIf="r.nextDate">
                        <span class="badge badge-warning">Voce già pianificata</span>
                    </ng-container>

                    <ng-container *ngIf="!r.nextDate">
                        <span class="badge badge-success">Nuova voce</span>
                    </ng-container>

                </td>
            </tr>
        </tbody>
    </table>
</app-modal>