import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Documents } from '../../db/documents';
import { DocumentsService } from '../../services/documents.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit {

  constructor(
    private documentsService:DocumentsService
  ) { }

  @Input()
  document:Documents;

  @Input()
  id_document:number=0;

  @Input()
  methodpayments:any;


  rt:string="";

  responseprinterOk=false;

  state_cashregister=0;

  

  ngOnInit(): void {

    if(this.document.id==undefined){
      this.document.id=0;
      this.document.enable_inventory=true;
    }


    

    Globals.c1connector.onMessageReceived.subscribe((msg)=>{
      if(msg['cmd']=="rt_connected"){
        if(this.rt==""){
          this.rt=msg['value'];
          this.state_cashregister=1;
          this.handleDocument();
        }
      }
      if(msg['cmd']=="responseprinted"){
        if(msg['id_document']!=this.document['id'])
              return; //scartalo
        if(msg['status']){
          if(!this.responseprinterOk){
            this.document.reference=msg['number'];
            this.responseprinterOk=true;
            this.confirmReceipt(()=>{
              this.closePrintModal();
            },msg['number']);
          }
        }
        if(!msg['status']){
          this.responseprinterOk=false;
          alert("Errore durante la stampa dello scontrino");
          this.state_cashregister=2; //imposta l'inserimento manuale
          return;

        }

        this.closePrintModal();
      }
    });

    this.connectCashRegister();

    setTimeout(()=>{
      if(this.state_cashregister==0){
        this.state_cashregister=2;
      }
    },2000);


  }

  connectCashRegister(){
    if(Globals.c1connector.connected){
      //invia il messaggio per il registratore di cassa
      let m={}
      m['cmd']="getRT";
      Globals.c1connector.sendMessage(m);
    }
 }

 handleDocument(onComplete=null){
  this.documentsService.createReceipt(this.document,(document)=>{
    this.document=document;
    if(this.state_cashregister==1)
      this.sendReceiptToRT(); //invia lo scontrino al registratore di cassa
    if(this.state_cashregister==2)
      this.confirmReceipt(()=>{
        if(onComplete)
            onComplete();
      }); //invia lo scontrino al registratore di cassa


  });
}


  sendReceiptToRT(){
    
    //invia lo scontrino al registratore di cassa
    let message:any={};
    message.cmd="receipt";
    
    let value:any={}
    value.id=this.document['id'];
    value.amount=this.document['total'];
    value.method_payment=this.document['methodpayment'][0]['methodpayment'];
    value.details=[];

    for(let i of this.document['details']){
      let detail:any={};
      detail.description=i['description'];
      detail.quantity=i['quantity'];
      detail.tax=i['tax'];
      detail.discount=Math.round(Helper.getEquivalentDiscount(i['discount1'],i['discount2'],i['discount3']));
      
      detail.price=(Math.round(i['netprice']*100)/100)*(1+i['tax']/100)*(1-Helper.getEquivalentDiscount(i['discount1'],i['discount2'],i['discount3'])/100);
      
      detail.price=(Math.round(detail.price * 100) / 100).toFixed(2);

      value.details.push(detail);
    } 


    message.value=value;
    
    Globals.c1connector.sendMessage(message);
    
  }

  confirmReceipt(onComplete,reference=""){
      //verifica se si tratta dello stesso id_document
      this.documentsService.confirmReceipt(this.document.id,JSON.stringify(this.methodpayments),reference).subscribe((result)=>{
        onComplete(result);
      });
      
      
    

  }

  cancelDocument(){
    if(this.document.id>0)
      this.documentsService.cancelDocumentCashRegister(this.document.id).subscribe(()=>{});
  }

  closePrintModal(result="success"){
    if(result!="success"){
      this.cancelDocument();
    }

    this['modalWindow'].close(result);
      
  }

  confirmManual(){
    this.handleDocument(()=>{
      this.closePrintModal();

    });  
   
  }

}
