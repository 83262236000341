import { Component, OnInit } from '@angular/core';
import { Helper } from 'projects/core/src/helper';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-documentoverview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {


  date_from: Date=new Date();
  date_to: Date=new Date();
  filter_date:Filter=new Filter();  
  created_from:number=0;
  list=[];

  constructor(
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.value=Helper.convertDateControl(this.date_from);
    this.filter_date.value2=Helper.convertDateControl(this.date_to);

    
  }

  getItems(){

    
    this.documentsService.getOverview(this.filter_date.value,this.filter_date.value2,this.created_from).subscribe((items)=>{
      this.list=items;
      
    });
  }

}
