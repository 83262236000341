import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router'
import {Globals} from './globals';
import { Injectable } from '@angular/core';
import { ToastMode } from './include/structures';

@Injectable()
export class AuthGuard implements CanActivate{

    constructor(public router:Router){};
   
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean{

        let authorized=true;
        let url=state.url;
        //se l'utente non loggato
        if(Globals.user==undefined){
            authorized=false;
        }else{
            //se l'utente è abilitato verifica che è abilitato a visualizzare la risorsa
            
            //verifica se è un modulo di amministrazione oppure di sito
            if(url.includes("admin")){
                //verifica che l'utente sia un amministratore
                return Globals.user.role.includes("admin");
                    
            }


            authorized=Globals.parameters.verifyAuth(url);
        }


       
        if(!authorized){
            Globals.message.showToaster("Utente non dispone dei permessi necessari per l'accesso del modulo "+url,ToastMode.DANGER);
//            Globals.navigation.onNavigate([Globals.access.loginPage]);
        }
            



        return authorized;
    }

}